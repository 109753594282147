
import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import { useState } from "react";
import Constants from "../../../constants/Constants";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteSubscriptionModal = ({ isOpen, onClose, id, refreshDetails }) => {
  const [isSpinning, setSpinning] = useState(false);

  const deleteSubscription = async () => {
    setSpinning(true);
    await api
      .delete(`${Constants.SUBSCRIPTION_DELETE_URI}/${id}/${0}`)
      .then(async (res) => {
        toast.success("😊 Subscription deleted successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setSpinning(false);
        refreshDetails();
      })
      .catch((error) => {
        toast.error(`😔 Error deleting subscription!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
          .finally(async () => {
            await onClose();
          })
      });
  };

  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        {/* <CModalHeader>
          <CModalTitle>
            <Text fontSize="xl"> Delete Subscription</Text>
          </CModalTitle>
        </CModalHeader> */}
        <CModalBody>
          <div>Do you want to delete this Subscription?</div>
          {!isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton
                color="danger"
                onClick={() => deleteSubscription()}
                size="sm"
              >
                Ok
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
          {isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="primary" disabled size="sm">
                <i class="fa fa-spinner fa-spin"></i> Deleting
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default DeleteSubscriptionModal;
