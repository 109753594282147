import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import api from "services/api.service";
import { useEffect, useState } from "react";
import Constants from "../../../constants/Constants";
import Loader from "components/loader/Loader";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";

const EditUserModal = ({ id, refreshDetails, isOpen, onClose }) => {
  const [preloadedValues, setPreloadedValues] = useState("");
  const [user, setUser] = useState(null);
  const [time, setTimeZone] = useState(null);
  const [isLoading, setLoaded] = useState(false);
  const [isSpinning, setSpinning] = useState(false);
  const [userTypes, setUserTypes] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: preloadedValues });
  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadTimeZone();
    loadUser();
    getAllUserTypes();
  }, []);

  const loadTimeZone = () =>
    api.get(Constants.TIMEZONE_GET_URI).then((res) => setTimeZone(res.data));

  const getAllUserTypes = () =>
    api.get(Constants.USERTYPES_GET_URI).then((res) => setUserTypes(res.data));

  const onSubmit = () => {
    setSpinning(true);
    api
      .post(Constants.USER_UPDATE_POST_URI + "/" + id, user)
      .then(async () => {
        toast.success("😊 Subscription added successfully.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        ////console.log(`User Updated successfully!`);
        refreshDetails();
      })
      .finally(() => setSpinning(false));
  };

  const loadUser = () => {
    setLoaded(true);
    api.post(Constants.USER_EDIT_URI + "/" + id)
      .then((res) => {
        toast.success("😊 User edited successfully.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        const values = {
          title: res.data.title,
          first_Name: res.data.first_Name,
          last_Name: res.data.last_Name,
          email: res.data.email,
          timezone: res.data.timezone,
          userType: res.data.userType,
        };
        setPreloadedValues(values);
        setUser(res.data);
      }).catch((err) => {
        toast.error(`😔 ${err.response.data.title}`, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .finally(() => setLoaded(false));
  };

  const TitleField = register("title", {
    required: true,
  });

  const FirstNameField = register("first_Name", {
    required: true,
  });

  const LastNameField = register("last_Name", {
    required: true,
  });

  const EmailField = register("email", {
    required: true,
  });

  const TimezoneField = register("timezone", {
    required: true,
  });
  const UserTypeField = register("userTypeID", {
    required: true,
  });

  return (
    <div>
      {!isLoading && user && time && userTypes && (
        <CModal show={isOpen} onClose={onClose} closeOnBackdrop={false}>
          <CModalHeader closeButton>
            <CModalTitle>
              {" "}
              <div>Edit User</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Title*</label>
                    <select
                      name="title"
                      defaultValue={user.title}
                      {...TitleField}
                      onChange={(e) => {
                        TitleField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    >
                      <option>Ms.</option>
                      <option>Mr.</option>
                      <option>Mrs.</option>
                    </select>
                    <small className="text-danger">
                      {errors.title?.type === "required" && "Title is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <label>First Name*</label>
                  <input
                    type="text"
                    name="first_Name"
                    defaultValue={user.first_Name}
                    {...FirstNameField}
                    onChange={(e) => {
                      FirstNameField.onChange(e);
                      onInputChange(e);
                    }}
                    className="form-control"
                  />
                  <small className="text-danger">
                    {errors.first_Name?.type === "required" &&
                      "First Name is required"}
                  </small>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Middle Name</label>
                    <input
                      type="text"
                      name="middle_Name"
                      defaultValue={user.middle_Name}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Last Name*</label>
                    <input
                      type="text"
                      name="last_Name"
                      defaultValue={user.last_Name}
                      {...LastNameField}
                      onChange={(e) => {
                        LastNameField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.last_Name?.type === "required" &&
                        "Last Name is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>E-mail*</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      defaultValue={user.email}
                      {...EmailField}
                      onChange={(e) => {
                        EmailField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Phone Number</label>
                    <input
                      type="text"
                      className="form-control "
                      name="phone"
                      defaultValue={user.phone}
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Timezone*</label>
                    <select
                      name="timezone"
                      {...TimezoneField}
                      onChange={(e) => {
                        TimezoneField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    >
                      <option defaultValue={user.timezone} name="timezone">
                        {user.displayName}
                      </option>
                      {time.map((temp) => {
                        return (
                          <>
                            <option value={temp.id} key={temp.id} name="timezone">
                              {temp.displayName}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.timezone?.type === "required" &&
                        "Timezone is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>UserType*</label>
                    <select
                      name="userTypeID"
                      {...UserTypeField}
                      onChange={(e) => {
                        UserTypeField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    >
                      <option value={user.userTypeID} name="userTypeID">
                        {user.userType}
                      </option>
                      {userTypes.map((item) => {
                        return (
                          <>
                            <option value={item.id}>{item.type}</option>
                          </>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.userTypeID?.type === "required" &&
                        "User Type is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Description</label>
                    <textarea
                      name="description"
                      className="form-control"
                      defaultValue={user.description}
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                  </CFormGroup>
                </CCol>
              </CRow>
              {!isSpinning && (
                <div className="float-right mb-1 mr-1 d-flex">
                  <CButton style={{ backgroundColor: "#1C6387", color: "white" }}
                    type="submit" size="sm">
                    Save Changes
                  </CButton>
                  <div className="ml-1">
                    <CButton onClick={onClose} color="secondary" size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div className="float-right mb-1 mr-1 d-flex">
                  <CButton disabled color="primary" size="sm">
                    <i className="fa fa-spinner fa-spin"></i> Updating
                  </CButton>
                  <div className="ml-1">
                    <CButton onClick={onClose} color="secondary" size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </form>
          </CModalBody>
        </CModal>
      )}

      <div />
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default EditUserModal;
