import {
  CSidebar,
  CSidebarNav,
  CSidebarNavDropdown,
  CSidebarNavItem
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";

import { authContext } from "auth/auth.provider";
import { memo, useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaChevronDown, FaChevronLeft } from "react-icons/fa";
import { FiFolder } from "react-icons/fi";
import { MdLogout, MdOutlineBookmarks, MdOutlineDashboard } from "react-icons/md";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { ApplicationState } from "store";
import { addSidebar } from "store/sidebar/action";
import navigation from "./_nav";

const LOCAL_STORAGE_KEY = 'sidebar-dropdowns';

const Sidebar = () => {
  const { onLogout } = useContext(authContext);
  const dispatch: Dispatch<any> = useDispatch();
  const sidebar: ISidebar = useSelector(
    (state: ApplicationState) => state.sidebar.sidebar
  );

  // Load initial openDropdowns from local storage or use default values
  const getInitialOpenDropdowns = () => {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedState) {
      return JSON.parse(savedState);
    } else {
      const dropdownNames = ["Records", "Other Stuffs"]; 
      return dropdownNames.reduce((acc, name) => {
        acc[name] = true;
        return acc;
      }, {} as { [key: string]: boolean });
    }
  };

  const [openDropdowns, setOpenDropdowns] = useState<{ [key: string]: boolean }>(getInitialOpenDropdowns);

  const handleLogout = () => {
    onLogout();
  };

  const toggleSidebar = () => {
    const val = !sidebar.showSidebar;
    dispatch(addSidebar({ showSidebar: val }));
  };

  const toggleDropdown = (name: string) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };


  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(openDropdowns));
  }, [openDropdowns]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove saved data from local storage
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (

    <>
      <div className={`sidebar ${sidebar.showSidebar ? '' : 'close'}`} id="tmf_sidebar_container">
        {
          sidebar.showSidebar ? (
            <>
              <div className="screen-view">
                <CSidebar colorScheme="light" >
                  <CSidebarNav   >
                    {/* <CCreateElement
                      items={navigation}
                      components={{
                        CSidebarNavDivider,
                        CSidebarNavDropdown,
                        CSidebarNavItem,
                        CSidebarNavTitle,
                      }}
                    /> */}

                    {navigation(openDropdowns).map((item, index) => {
                      if (item._tag === 'CSidebarNavDropdown') {
                        return (
                          <CSidebarNavDropdown
                            key={index}
                            name={item.name}
                            icon={item.icon}
                            style={item.style}
                            show={openDropdowns[item.name] || false}
                            onClick={() => toggleDropdown(item.name)}
                            className={`sidebar-item ${openDropdowns[item.name] ? 'c-show' : ''}`}
                          >
                            <span className="dropdown-toggle-icon">
                              {openDropdowns[item.name] ? <FaChevronDown /> : <FaChevronLeft />}
                            </span>

                            <div className={`dropdown-sidebar-item ${openDropdowns[item.name] ? '' : 'display-none'}`}  >
                              {item._children && item._children.map((child, idx) => (

                                <CSidebarNavItem
                                  key={idx}
                                  name={child.name}
                                  to={child.to}
                                  icon={child.icon}
                                  style={child.style}
                                  onClick={(e) => {

                                    e.stopPropagation();
                                  }}
                                />

                              ))}
                            </div>
                          </CSidebarNavDropdown>
                        );
                      } else {
                        return (
                          <CSidebarNavItem
                            key={index}
                            name={item.name}
                            to={item.to}
                            icon={item.icon}
                            style={item.style}
                          />
                        );
                      }
                    })}
                  </CSidebarNav>
                </CSidebar>

                <div className="logout-button">
                  <div className="collapses" onClick={toggleSidebar}>
                    <FaArrowLeft />
                    <button>
                      Collapse
                    </button>
                  </div>

                  <div className="logout" onClick={() => {
                    handleLogout();
                  }}>
                    <MdLogout />
                    <button >
                      Logout
                    </button>
                  </div>

                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mobile-view">
                <div className="mobile-sidebar">
                  <div className="dashboard-icon">
                    <Link to="/">
                      <MdOutlineDashboard style={{
                        height: "20px",
                        width: "20px",
                        paddingRight: "4px",
                      }} />
                    </Link >
                  </div>


                  <div className="icon-wrapper">
                    <  FiFolder
                      style={{
                        paddingRight: "4px",
                        height: "20px",
                        width: "20px"
                      }}
                    />
                    <div className="sub-items">
                      <div><Link to={"/organization"}>Organization</Link> </div>
                      <div> <Link to={"/users"}>User</Link> </div>
                      <div> <Link to={"/subscription-list"}>Subscription </Link> </div>
                      <div> <Link to={"/leads"}>Leads </Link></div>
                    </div>
                  </div>

                  <div className="icon-wrapper">
                    <  MdOutlineBookmarks
                      style={{
                        paddingRight: "4px",
                        height: "20px",
                        width: "20px"
                      }}
                    />
                    <div className="sub-items">
                      <div><Link to={"/plans"}>Plans</Link> </div>
                      <div> <Link to={"/offers"}>Offers</Link> </div>
                      <div> <Link to={"/partners"}>Partners</Link> </div>
                      <div> <Link to={"/icons"}>Icons</Link></div>
                    </div>
                  </div>
                </div>

                <div className="buttons">
                  <div className="collapse" style={{ paddingRight: "4px" }}>
                    <FaArrowRight style={{

                      height: "16px",
                      width: "16px"
                    }} onClick={toggleSidebar} />
                  </div>

                  <div className="logout" style={{ paddingRight: "4px" }}>
                    <MdLogout style={{

                      height: "20px",
                      width: "20px"
                    }} onClick={() => { handleLogout(); }} />
                  </div>
                </div>
              </div>
            </>
          )
        }

      </div>



    </>



  );
};

export default memo(Sidebar);

