import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import { FaRegTrashAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  setEditPlanModal,
  setDeletePlanModal,
  setViewPlanModal,
} from "store/plan/action";

interface IPlanActionParams {
  plan: Iplan;
}

const PlanActions = ({ plan }: IPlanActionParams) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <div style={{ display: "flex", height: "40px", justifyContent: "center" }}>
      <button
        onClick={() => {
          setViewPlanModal({ showModal: true, plan });
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          width: "84px",
          cursor: plan.isDeleted ? "not-allowed" : "pointer",
        }}
      >
        View
      </button>
      <TmfDropdown>
        {plan.isDeleted ? (
          <div className="px-2">No Actions</div>
        ) : (
          <>
            <TmfDropdownItem
              icon={<GoPencil/>}
              text="Edit"
              props={{
                onClick: () => {
                  dispatch(setEditPlanModal({ showModal: true, plan }));
                },
              }}
            ></TmfDropdownItem>
            <TmfDropdownItem
                icon={<FaRegTrashAlt/>}
              text="Delete"
              props={{
                onClick: () => {
                  dispatch(
                    setDeletePlanModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      plan,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>
          </>
        )}
      </TmfDropdown>
    </div>
  );
};

export default PlanActions;
