import { CPagination } from "@coreui/react";
import { Pagination, Stack, TablePagination } from "@mui/material";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const DTablePagination = ({ tPage, cPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  useEffect(() => {
    setCurrentPage(cPage);
  }, [cPage]);


  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handleDecreasePage = (page) => {
    if (page === 1) {
      return
    }
    setCurrentPage(page - 1);
    onPageChange(page - 1);
  }

  const handleIncreasePage = (page) => {
    if (page === tPage) {
      return;
    }
    setCurrentPage(page + 1);
    onPageChange(page + 1);
  }



  return (
    <div className="pagination-box">



      <button
        disabled={cPage === 1}
        style={{
          cursor: cPage === 1 ? "not-allowed" : "pointer",
        }}
        onClick={() => handleDecreasePage(cPage)}
      >
        <IoIosArrowBack />
      </button>
      <p>
        {currentPage} <span style={{ fontSize: "400" }}>of</span> {tPage ? tPage : "..."}
      </p>
      <button
        disabled={cPage === tPage}
        style={{
          cursor: cPage === tPage ? "not-allowed" : "pointer",
        }}
        onClick={() => handleIncreasePage(cPage)}
      >
        <IoIosArrowForward />
      </button>

      {/* <Pagination
        size="small"
        count={tPage}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
      /> */}
    </div>
  );
};
