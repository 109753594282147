import { cibLogstash } from "@coreui/icons";

import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CCol,
  CFormGroup,
  CButton,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CModalFooter,
} from "@coreui/react";
import moment from "moment";

import { useEffect, useState } from "react";

import { Controller } from "react-hook-form";

function ViewOrganizationDetails({ isOpen, onClose, organization }) {
  const [activeKey, setActiveKey] = useState(1);
  return (
    <div>
      {isOpen && (
        <CModal
          show={isOpen}
          onClose={onClose}
          closeOnBackdrop={false}
          // style={{ maxHeight: "90vh", overflowY: "scroll" }}
          style={{ borderTop: "5px solid #ccc" }}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Organization's Details</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CNav variant="tabs" role="tablist">
              <CNavItem>
                <CNavLink
                  href="javascript:void(0);"
                  active={activeKey === 1}
                  onClick={() => setActiveKey(1)}
                >
                  Admin
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="javascript:void(0);"
                  active={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                >
                  General
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="javascript:void(0);"
                  active={activeKey === 3}
                  onClick={() => setActiveKey(3)}
                >
                  Date
                </CNavLink>
              </CNavItem>
            </CNav>
            <CTabContent>
              {activeKey === 1 ? (
                <div className="tap-pane-show">
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">
                        Organization Name :
                      </span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.name}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Admin Name :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.adminName}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Admin Email :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.adminEmail}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Country :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.country_Name}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">City :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.city}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">State :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.state_Name}</span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {activeKey === 2 ? (
                <div className="container">
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Email :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.email}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Phone :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.phone}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Address :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.address}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Active User :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.activeUserCount}</span>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Total User :</span>
                    </div>
                    <div className="col-md-8">
                      <span>{organization.totalUserCount}</span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {activeKey === 3 ? (
                <div className="container">
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Start Date :</span>
                    </div>
                    <div className="col-md-8">
                      <span>
                        {moment(organization.creationDate).format(
                          "MMM DD YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                  {/* <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">End Date :</span>
                    </div>
                    <div className="col-md-8">
                      <span>
                        {moment(organization.EndDate).format("MMM DD YYYY")}
                      </span>
                    </div>
                  </div> */}
                  <hr className="my-0" />
                  <div className="row p-2">
                    <div className="col-md-4">
                      <span className="font-weight-bold">Modified Date :</span>
                    </div>
                    <div className="col-md-8">
                      <span>
                        {moment(organization.modifiedDate).format(
                          "MMM DD YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </CTabContent>
          </CModalBody>
          <CModalFooter>
            <CButton color="danger" size="sm" onClick={onClose}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}
    </div>
  );
}

export default ViewOrganizationDetails;
