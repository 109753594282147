export enum SubscriptionActionTypes {
  SET_SUBSCRIPTION_TYPES = "@@subscription/SET_SUBSCRIPTION_TYPES",
  SET_YEARLY_SUBSCRIPTIONS = "@@subscription/SET_YEARLY_SUBSCRIPTIONS",
  SET_SUBSCRIPTION_FILTERS_LOADER = "@@subscription/SET_SUBSCRIPTION_FILTERS_LOADER",
  SET_FILTERED_SUBSCRIPTIONS = "@@subscription/SET_FILTERED_SUBSCRIPTIONS",
  SET_FILTERED_SUBSCRIPTIONS_LOADER = "@@subscription/SET_FILTERED_SUBSCRIPTIONS_LOADER",
  SET_SUBSCRIPTION_FILTER = "@@subscription/SET_SUBSCRIPTION_FILTER",
  SET_SUBSCRIPTION_LOADER = "@@subscription/SET_SUBSCRIPTION_LOADER",
  SET_SUBSCRIPTION_STATUS = "@@subscription/SET_SUBSCRIPTION_STATUS",
  EDIT_SUBSCRIPTION_MODAL = "@@subscription/EDIT_SUBSCRIPTION_MODAL",
  DELETE_SUBSCRIPTION_MODAL = "@@subscription/DELETE_SUBSCRIPTION_MODAL",
  UNDO_DELETE_SUBSCRIPTION_MODAL = "@@subscription/UNDO_DELETE_SUBSCRIPTION_MODAL",
  VIEW_SUBSCRIPTION_DETAILS_MODAL = "@@subscription/VIEW_SUBSCRIPTION_DETAILS_MODAL",
  VIEW_SUBSCRIPTIONS_MAIL_MODAL="@@subscription/VIEW_SUBSCRIPTIONS_MAIL_MODAL",
}

export const subscriptionFilterInitials: ISubscriptionFilter = {
    // year: parseInt(moment(Date()).format("yyyy")),
    year: null,
    // monthName: moment(Date()).format("MMMM"),
    monthName: null,
    PlanID: null,
    OrgID: null,
    IsActive: null,
    SubscriptionTypeID: null,
    BeginDate: null,
    EndDate: null,
    expiryIn:null,
    GetExpired: null
};
