import { useEffect, useState } from "react";
import api from "services/api.service";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CCol,
  CFormGroup,
} from "@coreui/react";
import Constants from "../../../constants/Constants";
import Loader from "components/loader/Loader";
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { DefaultLeadsMail } from "./LeadsMailBody";
import { emailRegex } from "constants/commonConstants";

const LeadsMailModal = ({ selectedRows, lead, isOpen, onClose }) => {
  interface ILeadsMailModal {
    leadDetails?: {
      Id: number;
      email: string;
      fullName?: string;
    }[];
    subject?: string;
    emailBody?: any | HTMLElement;
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [mail, setMail] = useState<ILeadsMailModal>();
  const [isSpinning, setSpinning] = useState(false);
  const [isloading, setLoaded] = useState(false);
  const [mailContent, setMailContent] = useState<any | HTMLElement>();

  useEffect(() => {
    loadLeadsData();
  }, []);

  /*const loadLeadsData = async () => {
    let selectedLead;
    let selectedIndex;

    if (Array.isArray(lead) && selectedRows !== undefined) {
      selectedIndex = lead.findIndex((item) => item.email === selectedRows.lastItem);
      selectedLead = lead[selectedIndex];
    } else {
      selectedLead = lead;
    }

    setMail({
      ...mail,
      leadDetails: [{
        Id: selectedLead.id,
        email: selectedLead.email,
        fullName: selectedLead.firstName + " " + selectedLead.lastName,
      }],
      subject: `Don't miss to get registered`,
      emailBody: mailContent
    });
    setMailContent(DefaultLeadsMail());
  };*/
  const loadLeadsData = async () => {
    let selectedLeads: any[] = [];
    let selectedIndices: number[] = [];

    if (Array.isArray(lead) && Array.isArray(selectedRows)) {
      selectedLeads = selectedRows
        .filter((email) => emailRegex.test(email))
        .map((email) => {
          const selectedIndex = lead.findIndex((item) => item.email === email);
          selectedIndices.push(selectedIndex);
          return lead[selectedIndex];
        });
    } else {
      selectedLeads = Array.isArray(lead) ? lead : [lead];
    }

    setMail({
      ...mail,
      leadDetails: selectedLeads.map((selectedLead) => ({
        Id: selectedLead?.id,
        email: selectedLead?.email,
        fullName: selectedLead?.firstName + " " + selectedLead?.lastName,
      })),
      subject: `Don't miss to get registered`,
      emailBody: mailContent,
    });

    setMailContent(DefaultLeadsMail());
  };

  const onSubmit = () => {
    setSpinning(true);
    api
      .post(Constants.LEADS_SEND_MAIL, mail)
      .then(() => {
        onClose();
        // alert(`Mail send successfully!`);
        setMail({
          leadDetails: [
            {
              Id: 0,
              email: "",
              fullName: "",
            },
          ],
          subject: "",
          emailBody: "",
        });
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  const handleEditorChange = (content: any, editor: any) => {
    setMail({ ...mail, emailBody: content });
  };
  return (
    <>
      {isOpen && (
        <CModal
          show={isOpen}
          onClose={onClose}
          closeOnBackdrop={true}
          // size="lg"
          style={{ width: "600px", height: "650px" }}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              <div className="text-capitalize">Leads Mail</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >
              <CRow>
                <CCol>
                  <CFormGroup>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={lead?.email ? lead?.email : selectedRows}
                      onChange={(e) => {
                        setMail((mail) => ({
                          ...mail,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={mail?.subject}
                      onChange={(e) => {
                        setMail({ ...mail, subject: e.target.value });
                      }}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Message</label>
                    <div className="" style={{ border: "1px solid #ccc" }}>
                      <Editor
                        apiKey="8qt7b2xhusy7x4d51lu9o3nqw886wgi0ozb73g2m9ayui3wc"
                        init={{
                          skin: "snow",
                          icons: "thin",
                          height: 360,
                          width: 550,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen textcolor ",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          textcolor_rows: "4",
                          toolbar:
                            "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
                        }}
                        initialValue={mailContent}
                        onEditorChange={handleEditorChange}
                        // outputFormat="html"
                      />
                    </div>
                    <small className="text-danger">
                      {errors.message?.type === "required" &&
                        "Email is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton
                    style={{ backgroundColor: "#1C6387", color: "white" }}
                    type="submit"
                    size="sm"
                  >
                    Send
                  </CButton>

                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Cancel
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton
                    disabled
                    style={{ backgroundColor: "#1C6387", color: "white" }}
                    size="sm"
                  >
                    <i className="fa fa-spinner fa-spin"></i> Sending...
                  </CButton>
                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </form>
          </CModalBody>
        </CModal>
      )}
      {isloading && <Loader />}
    </>
  );
};

export default LeadsMailModal;
