import axios from "axios";
import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import ViewOrganizationDetails from "components/modals/organization/ViewOrganizationDetails";
import commonConstants from "constants/commonConstants";
import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userApi from "services/apis/user.api";
import { ApplicationState } from "store";
import { setEditOrganizationModal } from "store/organization/action";
import UserFilter from "views/users/userFIlter";
import { GoPencil } from "react-icons/go";
import { FaPrint, FaRegEye } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";

interface IOrganizationActionParams {
  organization: IOrganization;
}

const OrganizationActions = ({ organization }: IOrganizationActionParams) => {
  const [viewOrgDetails, setViewOrgDetails] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();

  const usersFilter = useSelector(
    (state: ApplicationState) => state.user.usersFilter
  );

  return (
    <div style={{ display: "flex", height: "40px", justifyContent: "center", outline: "none", border: "none" }}>
      <button
        onClick={() => {
          setViewOrgDetails(true);
        }}
        style={{
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          padding: "0px 15px"
        }}
      >
        View
      </button>
      <TmfDropdown>
        <TmfDropdownItem
          icon={<GoPencil/>}
          text="Edit"
          props={{
            onClick: () =>
              // handleEditOrganizationModal(organization.organizationId),
              dispatch(
                setEditOrganizationModal({
                  showModal: true,
                  isModalFormSubmitting: false,
                  organization,
                })
              ),
          }}
        ></TmfDropdownItem>
        {organization.totalSubscriptionCount > 0 && (
          <TmfDropdownItem
            icon={<CiCreditCard1/>}
            text="Subscriptions"
            props={{ to: `/subscription/${organization.organizationId}` }}
          ></TmfDropdownItem>
        )}

        {organization.totalSubscriptionCount > 0 && (
          <TmfDropdownItem
            icon={<FaPrint/>}
            text="License"
            props={{ to: `/license/${organization.organizationId}` }}
          ></TmfDropdownItem>
        )}

        {organization.totalUserCount > 0 && (
          <TmfDropdownItem
            icon={<FaRegEye />}
            text="View User"
            props={{
              onClick: () => {
                usersFilter.OrganizationID = organization.organizationId;
                usersFilter.IsActive = null;
              },
              to: `/users`,
            }}
          ></TmfDropdownItem>
        )}
      </TmfDropdown>

      {viewOrgDetails && (
        <ViewOrganizationDetails
          isOpen={viewOrgDetails}
          onClose={() => setViewOrgDetails(false)}
          organization={organization}
        />
      )}
    </div>
  );
};

export default OrganizationActions;
