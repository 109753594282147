import { CButton } from "@coreui/react";
import DataFilter from "components/common/dataFilter";
import commonConstants from "constants/commonConstants";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import organizationApi from "services/apis/organization.api";
import userApi from "services/apis/user.api";
import { ApplicationState } from "store";
import { setUsersFilter } from "store/user/action";

const UserFilter = ({ cPage, onPageChange }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [showFields, setShowFields] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const dateRangeRef = useRef<HTMLDivElement | null>(null);

  const organization = useSelector(
    (state: ApplicationState) => state.organization
  );
  const usersFilter = useSelector(
    (state: ApplicationState) => state.user.usersFilter
  );

  const [openSelects, setOpenSelects] = useState({
    organization: false,
    userStatus: false,
  });

  const handleSelectClick = (selectName) => {
    setOpenSelects(prev => ({
      ...prev,
      [selectName]: !prev[selectName]
    }));
  };


  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm();

  useEffect(() => {
    getOrganizationList();
    // loadDateRange();
  }, []);

  const getOrganizationList = () =>
    organizationApi.fetchAllOrganizations(
      dispatch,
      !(organization.allOrganizations.length > 0)
    );

  function handleChange(
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) {
    const selectedValue = e.target.value;
    const endDate = new Date();
    setSelectedDateRange(selectedValue);
    if (selectedValue == "Select Date Range") {
      setValue("BeginDate", null)
      setValue("EndDate", undefined);
      dispatch(
        setUsersFilter({
          ...usersFilter,
          BeginDate: null,
          EndDate: null,
        })
      );

      return;
    }
    if (selectedValue.includes("Last")) {

      const startDate = new Date();

      if (selectedValue === "Last 30 Days") {
        startDate.setDate(endDate.getDate() - 30);
      } else if (selectedValue === "Last 60 Days") {
        startDate.setDate(endDate.getDate() - 60);
      } else if (selectedValue === "Last 90 Days") {
        startDate.setDate(endDate.getDate() - 90);
      } else if (selectedValue === "Last Week") {
        startDate.setDate(endDate.getDate() - 7);
      }

      dispatch(
        setUsersFilter({
          ...usersFilter,
          BeginDate: startDate.toISOString().split("T")[0],
          EndDate: endDate.toISOString().split("T")[0],
        })
      );

      setValue("EndDate", endDate.toISOString().split("T")[0])
    } else {


      if (e.target.name === "EndDate") {
        setValue("EndDate", endDate.toISOString().split("T")[0])
      }

      dispatch(
        setUsersFilter({
          ...usersFilter,
          [e.target.name]: selectedValue?.length ? selectedValue : null,
        })
      );
    }
  }

  const checkEndData = watch("EndDate");

  const clearFilter = (clearAll = true) => {
    setSelectedDateRange("Select Date Range");
    usersFilter.BeginDate = null;
    usersFilter.EndDate = null;

    if (clearAll) {
      usersFilter.IsActive = null;
      usersFilter.OrganizationID = null;
      setSelectedDateRange("Select Date Range");
    }

    userApi.filterUsers(
      usersFilter,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );

    onPageChange(1);
    setValue("EndDate", undefined);
    setShowFields(false);
  };


  const loadDateRange = () => {
    userApi.filterUsers(
      usersFilter,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1);
  };

  // const EndDateField = register("EndDate", {
  //   required: true,
  // });

  const handleClicks = () => {
    setShowFields(!showFields);
  };



  //used to close the date range popup when click outside the popup box
  useEffect(() => {
    if (usersFilter.BeginDate !== null && checkEndData === undefined) {
      return;
    } else {
      const handleClickOutside = (event: MouseEvent) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
          if (dateRangeRef.current) {
            setShowFields(false)
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [usersFilter.BeginDate, checkEndData]);

  //used to call the user data when filter value is changed.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedDateRange !== "Select Date Range" && usersFilter.BeginDate == null && usersFilter.EndDate == null || selectedDateRange !== "Select Date Range" && usersFilter.BeginDate != null && usersFilter.EndDate != null) {
        loadDateRange()
        setShowFields(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [usersFilter])

  return (
    <DataFilter isLoading={organization.isAllOrganizationLoading}>
      <form
        onSubmit={handleSubmit(loadDateRange)}
        className="filter"
      >
        <div>
          <label>Organization</label>
          <div className="select-wrapper">
            <select
              name="OrganizationID"
              onChange={(e) => handleChange(e)}
              className="form-control"
              defaultValue={usersFilter?.OrganizationID?.toString()}
              onClick={() => handleSelectClick('organization')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, organization: false }))}
            >
              <option value="">Select Organization</option>
              {organization.allOrganizations.map((item, index) => {
                return (
                  <option value={item.organizationID} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {openSelects.organization ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>

        <div>
          <label>User-Status</label>
          <div className="select-wrapper">
            <select
              name="IsActive"
              onChange={(e) => handleChange(e)}
              className="form-control"
              defaultValue={usersFilter?.IsActive?.toString()}
              onClick={() => handleSelectClick('userStatus')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, userStatus: false }))}
            >
              <option value="">Select User Status</option>
              <option value="true">Active</option>
              <option value="false">InActive</option>
            </select>
            {openSelects.userStatus ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>
        {/* <div className="tmf-form-group">
          <label className="textBold">Start-Date</label>{" "}
          <input
            placeholder="date"
            className="form-control"
            type="date"
            name="BeginDate"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {usersFilter.BeginDate ? (
          <>
            <div className="tmf-form-group">
              <label className="textBold">End-Date</label>
              <input
                placeholder="date"
                className="form-control"
                type="date"
                {...EndDateField}
                onChange={(e) => {
                  EndDateField.onChange(e);
                  handleChange(e);
                }}
                min={usersFilter.BeginDate as string}
              />
              <small className="text-danger">
                {errors.EndDate?.type === "required" && "End Date is required"}
              </small>
            </div>
          </>
        ) : (
          <div className="tmf-form-group">
            <label className="textBold">End-Date</label>
            <input disabled className="form-control" type="date" />
          </div>
        )} */}
        <div className="relative">
          <label>Date Range</label>

          <div className="date-range">
            <input
              readOnly
              className="form-control"
              placeholder={`${usersFilter.BeginDate ? usersFilter.BeginDate : "Select Date Range"} ${usersFilter.EndDate ? "-" : ""} ${usersFilter.EndDate ? usersFilter.EndDate : ""}`}
            ></input>
            {
              showFields ? <RiArrowDropUpLine onClick={handleClicks} /> : <RiArrowDropDownLine onClick={handleClicks} />
            }
          </div>

          {showFields && (
            <div ref={dateRangeRef}
              className={`dateRangeRef ${showFields ? 'show' : ''}`}
            >
              <div className="flex" style={{ gap: "0.5rem", alignItems: "stretch", flexWrap: "wrap" }}>
                <fieldset>
                  <div className="tmf-form-group">
                    <label>Date Ranges</label>
                    <select
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={selectedDateRange}
                    >
                      <option value="Select Date Range">Select Date Range</option>
                      <option value="Last 30 Days">Last 30 Days</option>
                      <option value="Last 60 Days">Last 60 Days</option>
                      <option value="Last 90 Days">Last 90 Days</option>
                      <option value="Last Week">Last Week</option>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="tmf-form-group">
                    <label>Start Date</label>
                    <input
                      placeholder="date"
                      className="form-control sm"
                      type="date"
                      name="BeginDate"
                      value={`${usersFilter.BeginDate}`}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </fieldset>
                <fieldset>
                  {usersFilter.BeginDate ? (
                    <div className="tmf-form-group">
                      <label>End Date</label>
                      <input
                        placeholder="date"
                        className="form-control"
                        type="date"
                        value={`${usersFilter.EndDate}`}
                        // {...EndDateField}
                        name="EndDate"
                        onChange={(e) => {
                          // EndDateField.onChange(e);
                          handleChange(e);
                        }}
                        min={usersFilter.BeginDate as string}
                      />
                      <small className="text-danger">
                        {checkEndData === undefined ? "End Date is required" : ""}
                      </small>
                    </div>
                  ) : (
                    <div className="tmf-form-group">
                      <label>End Date</label>
                      <input disabled className="form-control" type="date" />
                    </div>
                  )}
                </fieldset>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "12px",
                  gap: "20px",
                }}
              >
                {/* <div className="tmf-filter__submit">
                  <button
                    className="apply"
                    type="submit"
                    disabled={checkEndData === undefined ? true : false}
                    onClick={() => {
                      loadDateRange();
                      setShowFields(!showFields);
                    }}
                  >
                    Apply
                  </button>
                </div> */}
                <div className="tmf-filter__submit">
                  <button
                    className="clear"
                    type="reset"
                    onClick={() => clearFilter(false)}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div style={{ marginTop: "25px" }}>
          <button
            className="apply"
            type="submit"
            onClick={() => loadDateRange()}
          >
            Apply
          </button>
        </div>

        <div style={{ marginTop: "25px" }}>
          <button className="clear" type="reset" onClick={() => clearFilter()}>
            Clear
          </button>
        </div>

        {/* <div className="tmf-filter__submit">
          <button
            onClick={() => {
              loadDateRange();
              // setShowFields(!showFields);
            }}
            className="apply"
            type="submit"
          >
            Apply
          </button>
        </div>
        <div className="tmf-filter__submit">
          <button onClick={() => clearFilter()} type="reset" className="clear">
            Clear
          </button>
        </div> */}
      </form>
    </DataFilter>
  );
};

export default UserFilter;
