import axios from "axios";

import Constants from "constants/Constants";
import commonConstants from "constants/commonConstants";
import { TotalCountContext } from "context/TotalCount";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import organizationApi from "services/apis/organization.api";
import { ApplicationState } from "store";

import {
  CDataTable,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CSwitch
} from "@coreui/react";
import { DTablePagination } from "components/datatable/pagination";
import AddOrganizationModal from "components/modals/organization/AddOrganizationModal";
import EditOrganizationModal from "components/modals/organization/EditOrganizationModal";
import {
  setAddOrganizationModal,
  setEditOrganizationModal
} from "store/organization/action";
import OrganizationCopilotModal from "./OrganizationCopilotModal";
import OrganizationCourtModal from "./OrganizationCourtModal";
import OrganizationSafeLinkModal from "./OrganizationSafeLink";
import OrganizationActions from "./organizationActions";
import OrganizationFilter from "./organizationFilter";
import OrganizationStatusModal from "./organizationStatusModal";

import TMFSearch from "components/input/TMFSearch";
import { Dispatch } from "redux";
import { setLeadSearchValue } from "store/leads/action";
import "styles/_dropdown.scss";
import OrganizationTableVariables from "./organizationTableVariables";
import TmfDropdown from "components/dropdown/dropdown";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const Organizations = () => {
  const totalData = useContext(TotalCountContext);
  const dispatch: Dispatch<any> = useDispatch();
  const [pageSize, setPageSize] = useState(commonConstants.PAGE_SIZE);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState<number>();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [countOrg, setCountOrg] = useState();
  const [allOrganizationList, setAllOrganizationList] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    loadDateRange();
  }, [pageNo]);

  useEffect(() => {
    getAllOrgDataCount();
    getAllOrganization();
  }, []);

  const getAllOrganization = () => {
    setLoading(true);
    axios
      .post(Constants.DATE_WISE_ORGANIZATION_FILTER_URI, organizationFilters)
      .then((res) => {
        setAllOrganizationList(res.data);
        setLoading(false);
        setButtonDisabled(false);
        dispatch(setOrganizations(res.data));
      })
      .catch((err) => { });
  };

  const getAllOrgDataCount = () => {
    axios.post(Constants.GET_ORG_COUNT, organizationFilters).then((res) => {
      setTotalPage(Math.ceil(Math.abs(res.data / commonConstants.PAGE_SIZE)));
      setCountOrg(res.data);
    });
  };

  const organization = useSelector(
    (state: ApplicationState) => state.organization
  );

  const isFilterLoading = useSelector(
    (state: ApplicationState) => state.organization.isLoading
  );

  console.log("organization",organization);
  



  const [organizationStatusModal, setOrganizationStatusModal] = useState(false);
  const [organizationCourtModal, setOrganizationCourtModal] = useState(false);
  const [organizationSafeLinkModal, setOrganizationSafeLinkModal] =
    useState(false);
  const [organizationCopilotModal, setOrganizationCopilotModal] =
    useState(false);
  const [selectedOrganization, setSelectedOrganization] =
    useState<IOrganization>();
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const organizationFilters = useSelector(
    (state: ApplicationState) => state.organization.organizationFilters
  );


  const loadDateRange = () => {
    setIsSearchActive(false)
    dispatch(setLeadSearchValue(""));
    organizationApi.filterOrganization(
      organizationFilters,
      dispatch,
      pageSize,
      pageNo
    );
  };

  function handleOrganizationStatus(item: IOrganization) {
    setSelectedOrganization(item);
    setOrganizationStatusModal(true);
  }
  const editOrganizationModal = useSelector(
    (state: ApplicationState) => state.organization?.editOrganizationModal
  );
  function handleOrganizationCourtLink(item: IOrganization) {
    setSelectedOrganization(item);
    setOrganizationCourtModal(true);
  }
  function handleOrganizationSafeLink(item: IOrganization) {
    setSelectedOrganization(item);
    setOrganizationSafeLinkModal(true);
  }
  function handleOrganizationCopilot(item: IOrganization) {
    setSelectedOrganization(item);
    setOrganizationCopilotModal(true);
  }


  const getOrganizationType = (organizationTypeId: number) => {
    switch (organizationTypeId) {
      case 1:
        return "Organizational";
      case 2:
        return "Educational";
      case 3:
        return "General";
      default:
        return "...";
    }
  };
  const csvLinkRefCurrentPage = useRef<
    HTMLAnchorElement & CSVLink & { link: HTMLAnchorElement }
  >(null);

  const handleCSVClick = () => {
    csvLinkRefCurrentPage.current?.link.click();
  };


  //Component
  const OrganizationPageTitleBuild = () => {
    return (
      <>
        <div className="title">
          <div className="page-title">Organizations</div>
          <div>
            <button
              className="create"
              onClick={() => {
                dispatch(setAddOrganizationModal(true));
              }}
            >
              Add New
            </button>
          </div>
        </div>
      </>
    );
  };

  const OrganizationTableBuild = () => {
    return (

      <div className="tmf-table_wrapper">
        <div
          className={`tmf-datatable__wrapper tmf_hide_build_in_pagination    ${isSearchActive && "tmf_paggination_state"
            }`}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}
        >
          <div
            style={{
              borderTopColor: "#fff",
              width: "1200px",
              minWidth: "100%",
              overflowX: "auto",
            }} >

            <div
              className="table-filter-section"
            >
              <TMFSearch
                sapi={Constants.ORGANIZATION_SEARCH_URI}
                dispatch={dispatch}
                isSearchActive={(e) => setIsSearchActive(e)}
                searchIn="organizations"
                onCalcelSearch={() => loadDateRange()}
              />
              <div
                style={{ display: "flex", alignItems: "center", gap: "30px" }}
              >
                <div style={{ position: "relative" }}>
                  <div className="d-flex" >
                    <button className="exportcsv">
                      Export To CSV
                    </button>
                    <button className="open-button"

                      onClick={() => setIsOpen((prev) => !prev)}
                    >
                      {
                        isOpen ? <RiArrowDropUpLine style={{ width: '25px', height: "25px" }} /> : <RiArrowDropDownLine style={{ width: '25px', height: "25px" }} />
                      }
                    </button>

                  </div>

                  {
                    isOpen && (
                      <div className={`dropdownmenubox ${isOpen ? "show" : "hide"}`}   >
                        <span style={{ color: "var(--txt-primary)", }} onClick={() => setIsOpen((prev) => !prev)}>
                          <CSVLink
                            headers={OrganizationTableVariables.headers}
                            data={allOrganizationList}
                            filename="AllOrganizations.csv"
                            ref={csvLinkRef}
                            style={{ color: "var(--txt-primary)" }}
                          >
                            Export All
                          </CSVLink>
                        </span>

                        <div>
                          <div
                            onClick={() => { handleCSVClick(); setIsOpen((prev) => !prev); }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              color: "var(--txt-primary)",
                              outline: "none",
                              cursor: "pointer"
                            }}
                          >
                            Export Current Page Data
                          </div>
                          <CSVLink
                            headers={OrganizationTableVariables.headers}
                            data={organization.organizations}
                            filename="CurrentPageOrganizationsList.csv"
                            ref={csvLinkRefCurrentPage}
                          />
                        </div>

                      </div>
                    )
                  }
                </div>

                {
                  !isSearchActive &&
                  <span style={{ marginLeft: "auto" }}>
                    <DTablePagination
                      cPage={pageNo}
                      tPage={totalPage}
                      onPageChange={(e) => {
                        setPageNo(e);
                        // loadDateRange();
                      }}
                    ></DTablePagination>
                  </span>
                }

              </div>
            </div>

            <CDataTable
              items={organization.organizations}
              fields={OrganizationTableVariables.fields}
              hover
              responsive={true}
              loading={isFilterLoading}
              scopedSlots={{
                name: (item: IOrganization) => (
                  <td>
                    <div className="name">
                      <span
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: "500",
                          color: "var(--txt-primary, #23005B)",


                        }}
                      >
                        {item.name}
                      </span>

                      <span style={{
                        display: "flex",
                        gap: "0.8rem",
                        fontSize: "0.875rem",
                        fontWeight: "400"

                      }}>
                        <span>{getOrganizationType(item.organizationTypeId)}</span>
                        <span>
                          Created:{item.creationDate}
                        </span>
                      </span>
                    </div>
                  </td>
                ),
                detail: (item: IOrganization) => (
                  <td>
                    <div className="detail">
                      <span style={{ color: "var(--txt-primary, #23005B)" }}>
                        {item.email ? item.email : "..."}
                      </span>
                      <span >{item.phone}</span>
                    </div>
                  </td>
                ),
                address: (item: IOrganization) => (
                  <td>
                    <div className="address">
                      <span style={{ fontSize: "1rem" }}>
                        {item.city ? item.city : "N/A"}
                      </span>
                      <span
                        style={{
                          color: "var(--txt-primary, #23005B)",
                          paddingTop: "6px",
                          fontSize: "1rem"
                        }}
                      >
                        {item.state_Name}
                      </span>
                      <span
                        style={{
                          color: "var(--txt-primary, #23005B)",
                          paddingTop: "6px",
                          fontSize: "1rem"
                        }}
                      >
                        {item.country_Name}
                      </span>
                    </div>
                  </td>
                ),
                record: (item: IOrganization) => {
                  return (
                    (
                      <td>
                        <div className="record">
                          <div className="record-item">
                            <div>
                              <span >
                                {item.totalUserCount}
                              </span>
                              Users
                            </div>
                            <div>
                              <span >
                                {item.totalLicense}
                              </span>
                              Licenses
                            </div>
                          </div>

                          <div className="check-box">
                            <div style={{ gap: "5px" }} className="checkbox-break">
                              <div>
                                <span className="checkbox-item">
                                  {item.isDeleted ? (
                                    <CSwitch
                                      disabled
                                      color={item.isActive ? "success" : "danger"}
                                      defaultChecked={item.isActive}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"

                                    />
                                  ) : (
                                    <CSwitch
                                      color={item.isActive ? "success" : "danger"}
                                      defaultChecked={item.isActive}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"
                                      onChange={(e) => handleOrganizationStatus(item)}
                                      id={"planSwitch" + item.isActive}
                                    />
                                  )}
                                  <span>Active</span>
                                </span>
                                <span className="checkbox-item">
                                  {item.isDeleted ? (
                                    <CSwitch
                                      disabled
                                      color="success"
                                      defaultChecked={item.courtLink}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"
                                    />
                                  ) : (
                                    <CSwitch
                                      color="success"
                                      defaultChecked={item.courtLink}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"
                                      onChange={(e) =>
                                        handleOrganizationCourtLink(item)
                                      }
                                      id={"planSwitch" + item.courtLink}
                                    />
                                  )}

                                  <span> Court link</span>
                                </span>
                              </div>

                              <div>
                                <span className="checkbox-item">
                                  {item.isDeleted ? (
                                    <CSwitch
                                      disabled
                                      color="success"
                                      defaultChecked={item.safeLink}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"
                                    />
                                  ) : (
                                    <CSwitch
                                      color="success"
                                      defaultChecked={item.safeLink}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"
                                      onChange={(e) => handleOrganizationSafeLink(item)}
                                      id={"planSwitch" + item.safeLink}
                                    />
                                  )}
                                  <span>Safe Link</span>
                                </span>
                                <span className="checkbox-item">
                                  {item.isDeleted ? (
                                    <CSwitch
                                      disabled
                                      color="success"
                                      defaultChecked={item.aiCopilot}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"
                                    />
                                  ) : (
                                    <CSwitch
                                      color="success"
                                      defaultChecked={item.aiCopilot}
                                      labelOn="On"
                                      labelOff="Off"
                                      shape="pill"
                                      variant="opposite"
                                      size="sm"
                                      onChange={(e) => handleOrganizationCopilot(item)}
                                      id={"planSwitch" + item.aiCopilot}
                                    />
                                  )}
                                  <span>Copilot</span>
                                </span>
                              </div>


                            </div>
                          </div>
                        </div>
                      </td>
                    )
                  )
                },
                Action: (item: IOrganization) => (
                  <td>
                    <OrganizationActions
                      organization={item}
                    ></OrganizationActions>
                  </td>
                ),
              }}
            />

            {!isSearchActive &&
              (
                <span style={{ marginLeft: "auto" }}>
                  <DTablePagination
                    cPage={pageNo}
                    tPage={totalPage}
                    onPageChange={(e) => {
                      setPageNo(e);
                      loadDateRange();
                    }}
                  ></DTablePagination>
                </span>
              )
            }



          </div>
        </div>
      </div>


    );
  };

  const OrganizationModalCallsBuild = () => {
    return (
      <>
        {editOrganizationModal?.showModal && (
          <EditOrganizationModal
            refreshDetails={() => loadDateRange()}
            organizationId={editOrganizationModal?.organization?.organizationId}
            isOpen={editOrganizationModal.showModal}
            onClose={() =>
              dispatch(setEditOrganizationModal({ showModal: false }))
            }
          />
        )}
        {organizationStatusModal && (
          <OrganizationStatusModal
            onClose={() => setOrganizationStatusModal(false)}
            organization={selectedOrganization!}
            refreshDetails={() => loadDateRange()}
          />
        )}
        {organizationCourtModal && (
          <OrganizationCourtModal
            onClose={() => setOrganizationCourtModal(false)}
            organization={selectedOrganization!}
            refreshDetails={() => loadDateRange()}
          />
        )}
        {organizationSafeLinkModal && (
          <OrganizationSafeLinkModal
            onClose={() => setOrganizationSafeLinkModal(false)}
            organization={selectedOrganization!}
            refreshDetails={() => loadDateRange()}
          />
        )}
        {organizationCopilotModal && (
          <OrganizationCopilotModal
            onClose={() => setOrganizationCopilotModal(false)}
            organization={selectedOrganization!}
            refreshDetails={() => loadDateRange()}
          />
        )}
        <AddOrganizationModal refreshDetails={() => loadDateRange()} />
      </>
    );
  };

  return (
    <>
      <OrganizationPageTitleBuild></OrganizationPageTitleBuild>

      <OrganizationFilter
        cPage={pageNo}
        onPageChange={(e) => {
          setPageNo(e);
          getAllOrgDataCount();
        }}
      ></OrganizationFilter>

      <OrganizationTableBuild></OrganizationTableBuild>



      <OrganizationModalCallsBuild></OrganizationModalCallsBuild>
    </>
  );
};

export default Organizations;
