import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import { Dispatch, useState } from "react";
import { CiCreditCard1, CiUndo } from "react-icons/ci";
import { FaRegEnvelope, FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch } from "react-redux";
import {
  setEditSubscriptionModal,
  setDeleteSubscriptionModal,
  setUndoDeleteSubscriptionModal,
  setViewSubscriptionModal,
  setViewSubscriptionMailModal,
} from "store/subscription/action";
interface ISubscriptionActionParams {
  subscription: ISubscription;
}

const SubscriptionActions = ({ subscription }: ISubscriptionActionParams) => {
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <div style={{ display: "flex", height: "40px" }}>
      <button
        onClick={() => {
          dispatch(
            setEditSubscriptionModal({
              showModal: true,
              isModalFormSubmitting: false,
              subscription,
            })
          );
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          width: "84px",
        }}
      >
        View
      </button>
      <TmfDropdown>
        {subscription.isDeleted ? (
          <>
            <TmfDropdownItem
              color=""
              icon={<CiUndo/>}
              text="Undo Delete"
              props={{
                onClick: () => {
                  dispatch(
                    setUndoDeleteSubscriptionModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      subscription,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>
          </>
        ) : (
          <>
            <TmfDropdownItem
               icon={<FaRegEye />}
              text="View"
              props={{}}
            ></TmfDropdownItem>
            <TmfDropdownItem
              icon={<GoPencil/>}
              text="Edit"
              props={{
                onClick: () => {
                  dispatch(
                    setEditSubscriptionModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      subscription,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>

            
            <TmfDropdownItem
              icon={<FaRegTrashAlt/>}
              text="Delete"
              props={{
                onClick: () => {
                  dispatch(
                    setDeleteSubscriptionModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      subscription,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>
            {subscription.msThirdPartySubscriptionID ? (
              <>
                <TmfDropdownItem
                   icon={<CiCreditCard1/>}
                  text="Payment History"
                  props={{
                    to: `/payment/${subscription.msThirdPartySubscriptionID}`,
                  }}
                ></TmfDropdownItem>
              </>
            ) : (
              <></>
            )}

            <TmfDropdownItem
              icon={<FaRegEnvelope />}
              text="Send Mail"
              props={{
                onClick: () => {
                  dispatch(
                    setViewSubscriptionMailModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      subscription,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>
          </>
        )}
      </TmfDropdown>
    </div>
  );
};

export default SubscriptionActions;
