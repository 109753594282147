import axios from "axios";
import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import ViewLeads from "components/modals/leads/ViewLeads";
import Constants from "constants/Constants";
import { Dispatch, useEffect, useState } from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setViewLeadsMailModal } from "store/leads/action";

interface ILeadsActionParams {
  lead: Ilead[];
}

const LeadsActions = ({ lead }: ILeadsActionParams) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [viewLeadsDetails, setViewLeadsDetails] = useState(false);

  // const handleLeadsMail = async () => {
  //   await axios.post(Constants.LEADS_SEND_MAIL, {
  //     "FullName": `${leads.firstName} ${leads.lastName}`,
  //     "Email": `${leads.email}`
  //   }).then(res => {

  //   });

  // }

  return (
    <div style={{ display: "flex", height: "40px" }}>
      <button
        onClick={() => {
          setViewLeadsDetails(true);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          width: "84px",
        }}
      >
        View
      </button>
      <TmfDropdown>
        {/* <TmfDropdownItem
          icon="eye"
          text="View"
          props={{
            onClick: () => setViewLeadsDetails(true),
          }}
        ></TmfDropdownItem> */}
        <TmfDropdownItem
          icon={<FaRegEnvelope/>}
          text="Send Mail"
          props={{
            onClick: () => {
              dispatch(
                setViewLeadsMailModal({
                  showModal: true,
                  isModalFormSubmitting: false,
                  lead,
                  selectedRows: null,
                })
              );
            },
          }}
        ></TmfDropdownItem>
      </TmfDropdown>
      {viewLeadsDetails && (
        <ViewLeads
          isOpen={viewLeadsDetails}
          onClose={() => setViewLeadsDetails(false)}
          leads={lead}
        />
      )}
    </div>
  );
};

export default LeadsActions;
