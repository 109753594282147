import AvishModal from 'components/modal/modal';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import axios from 'axios';
import { constants } from 'buffer';
import Constants from 'constants/Constants';
import Swal from 'sweetalert2';
import AvishButton from 'components/button/button';
interface Record {
    id: number;
    name: string;
    value: number;
}
function ImportLeads(props) {
    const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { handleSubmit } = useForm();

    const [file, setFile] = useState<File | null>(null);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFile(file);
        }
        if (file?.type !== "text/csv") {
            setError(true);
        } else {
            setError(false);
        }
    };
    const downLoadMeeting = () => {
        axios({
            url: Constants.LEAD_TEMPLATE_DOWNLOAD,
            method: "post",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "LeadTemplateSample.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    };
    const submit = async () => {
        if (!file) return;
        if (file.type === "text/csv") {
            const formData = new FormData();
            formData.append('file', file);
            setIsFormSubmitting(true);
            try {
                const response = await axios.post(Constants.IMPORT_LEAD_CSV, formData);
                if (response.data < 1) {
                    Swal.fire({
                        title: "No data imported!",
                        icon: "error",
                        position: "top-end",
                        timer: 2000,
                        showConfirmButton: false,
                        customClass: {
                            title: "title-class h6",
                            icon: "icon-class small font-weight-200",
                        },
                    });
                } else {
                    Swal.fire({
                        title: `${response.data} data imported`,
                        icon: "success",
                        position: "top-end",
                        timer: 2000,
                        showConfirmButton: false,
                        customClass: {
                            title: "title-class h6",
                            icon: "icon-class small font-weight-200",
                        },
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsFormSubmitting(false);
                props.onClose();
                props.refreshLeads();
            }
        }
    }

    return (
        <div>
            <AvishModal
                submitButtonType="submit"
                submitButtonValue="Import"
                title="Import Leads"
                icon={<i className='fa fa-upload'></i>}
                onClose={props.onClose}
                onSubmit={submit}
                showModal={true}
                isFormSubmitting={isFormSubmitting}
            >
                <div className="form-group">
                    <div className="mb-2 small">
                        You can import leads using a comma separated values file (CSV).
                        For best results, make sure that the file has UTF-8 encoding.
                    </div>
                    <AvishButton
                        onClick={() => downLoadMeeting()}
                        value="Download"
                    ></AvishButton>
                    <label htmlFor="leadCsvFile" className='small text-info d-block w-100 mt-2' style={{ cursor: "pointer" }}>
                        <span>Please upload your csv file.*</span>

                        <div className="form-control w-100">
                            <i className="fa fa-upload fa-7x"></i><span className='pl-2 my-2 small font-weight-bold'>{file?.name}</span>
                        </div>
                    </label>
                    <input type="file" onChange={handleFileChange} id="leadCsvFile" className='form-control' style={{ paddingBottom: "32px", display: "none", position: "absolute" }} accept=".csv" />
                    {error && <span className='text-danger small'>please check your file type!</span>}
                </div>
            </AvishModal>
        </div >
    );
}

export default ImportLeads