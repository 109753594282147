
import { MdOutlineBookmarks, MdOutlineDashboard } from "react-icons/md";
import { FiFolder } from "react-icons/fi";


interface ISidebarNavgation {
  _tag: string;
  name: string;
  to?: string;
  icon?: JSX.Element;
  style?: any;
  _children?: ISidebarNavgation[],
  isOpen?: boolean;

}

const _nav= (openDropdowns: { [key: string]: boolean }): ISidebarNavgation[] => [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/",
    icon: (
      <MdOutlineDashboard style={{
        height: "20px",
        width: "20px",
        paddingRight: "4px",
      }} />
    ),
    style: { fontWeight: "500", height: "3.2rem", fontSize: "14px" }
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Records",
    icon: <FiFolder
      style={{
        paddingRight: "4px",
        height: "20px",
        width: "20px"
      }}
    />,
    style: { fontWeight: "500", fontSize: "14px" },
    isOpen: openDropdowns["Records"], 
    _children: [ // Add child items here
      {
        _tag: "CSidebarNavItem",
        name: "Organization",
        to: "/organization",
        icon: <img src="" alt="" />,
        style: {
          fontWeight: "500",
          height: "2.5rem",
          fontSize: "14px",
          paddingLeft: "60px"
        },
      },
      {
        _tag: "CSidebarNavItem",
        name: "Users",
        to: "/users",
        icon: <img src="" alt="" />,
        style: { fontWeight: "500", height: "2.7rem", fontSize: "14px", paddingLeft: "60px" },
      },

      {
        _tag: "CSidebarNavItem",
        name: " Subscription",
        to: "/subscription-list",
        icon: <img src="" alt="" />,
        style: { fontWeight: "500", height: "2.7rem", fontSize: "14px", paddingLeft: "60px" },
      },
      {
        _tag: "CSidebarNavItem",
        name: "Leads",
        to: "/leads",
        icon: <img src="" alt="" />,
        style: { fontWeight: "500", height: "2.7rem", fontSize: "14px", paddingLeft: "60px" },
      },
    ],

  },


  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Links",
  //   to: "/links",
  //   icon: <FaIcons style={fontStyles} />,
  // },

  {
    _tag: "CSidebarNavDropdown",
    name: "Other Stuffs",
    icon: (
      <MdOutlineBookmarks
        style={{
          paddingRight: "4px",
          height: "20px",
          width: "20px"
        }}
      />
    ),
    style: { fontWeight: "500", fontSize: "14px" },
    isOpen: openDropdowns["Other Stuffs"],
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Plans",
        to: "/plans",
        icon: <img src="" alt="" />,
        style: { fontWeight: "500", height: "2.7rem", fontSize: "14px", paddingLeft: "60px" },
      },
      {
        _tag: "CSidebarNavItem",
        name: "Offers",
        to: "/offers",
        icon: <img src="" alt="" />,
        style: { fontWeight: "500", height: "2.7rem", fontSize: "14px", paddingLeft: "60px" },
      },
      {
        _tag: "CSidebarNavItem",
        name: "Partners",
        to: "/partners",
        icon: <img src="" alt="" />,
        style: { fontWeight: "500", height: "2.7rem", fontSize: "14px", paddingLeft: "60px" },
      },
      {
        _tag: "CSidebarNavItem",
        name: "Icons",
        to: "/icons",
        icon: <img src="" alt="" />,
        style: { fontWeight: "500", height: "2.7rem", fontSize: "14px", paddingLeft: "60px" },
      },
    ]
  },

];

export default _nav;
