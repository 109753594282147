import api from "services/api.service";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Constants from "../../../constants/Constants";
import {  CSpinner, CRow, CCol } from "@coreui/react";
import "../../../styles/_invoice.scss";
import moment from "moment";

const Invoice = () => {
  const [invoice, setInvoice] = useState([]);
  const { subscriptionID } = useParams();
  const [isloading, setLoaded] = useState(false);

  useEffect(() => {
    loadInvoice();
  }, []);

  const loadInvoice = () => {
    api
      .getWithParams(Constants.INVOICE_URI + "/" + subscriptionID, {
        withCredentials: true,
      })
      .then((res) => {
        setInvoice(res.data);
        setLoaded(true);
      });
  };

  //
  const generateInvoice = (id, date) => {
    let d = new Date(date);
    return `${d.getDate()}${d.getMonth()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${id}`;
  };

  // local components
  //console.log("invoice::", invoice);
  const InvoiceHeader = () => {
    return (
      <>
        <div className="tmf_invoice_header_container text-right">
          <div className="org_details d-flex flex-column align-items-end">
            <div className="d-flex">
              <img
                src={process.env.REACT_APP_BASEURL + "/32x32.png"}
                alt="TagMyFav Logo"
                className=""
                style={{ height: "18px", width: "18px" }}
              />
              <p className="text-bold">
                <strong>&nbsp;TagMyFav</strong>
              </p>
            </div>
            <p className="text-info tmf_desc">save.share.send</p>
          </div>
          <div className="org_phone">
            <span>Phone</span>
            <span>84512 </span>
          </div>
          <div className="org_mail">
            <span>Mail</span>
            <span>84512 </span>
          </div>
        </div>
      </>
    );
  };

  const InvoiceBody = () => {
    return (
      <div>
        <CRow>
          <CCol>
            <hr />
          </CCol>
          <CCol className="text-center">
            <span>
              <strong>
                <pre>TAX INVOICE</pre>
              </strong>
            </span>
          </CCol>
          <CCol>
            <hr />
          </CCol>
        </CRow>
        <CRow>
          <CCol md={7}>
            <div>
              <span>
                <strong>Bill To</strong>
              </span>
              <pre>
                <p>
                  <strong>{invoice.name}</strong>
                </p>
                <p>{invoice.address}</p>
                <p>{invoice.city}</p>
                <p>{invoice.stateName}</p>
                <p>{invoice.countryName}</p>
              </pre>
            </div>
            <div>
              <span>
                <strong>Ship To</strong>
              </span>
              <pre>
                <p>
                  <strong>{invoice.name}</strong>
                </p>
                <p>{invoice.address}</p>
                <p>{invoice.city}</p>
                <p>{invoice.stateName}</p>
                <p>{invoice.countryName}</p>
              </pre>
            </div>
          </CCol>
          <CCol>
            <pre>
              <p>
                <strong>Tax Invoice#:&nbsp;</strong>
                <span>
                  {generateInvoice(subscriptionID, invoice.purchaseDate)}
                </span>
              </p>
              <p>
                <strong>Invoice Date:&nbsp;</strong>
                <span>
                  {moment(invoice.purchaseDate).format("MMM DD YYYY")}
                </span>
              </p>
              <p>
                <strong>P.O#:&nbsp;</strong>
                <span>--------------</span>
              </p>
              <p>
                <strong>User Mail:&nbsp;</strong>
                <span>{invoice.purchasedBy}</span>
              </p>
            </pre>
          </CCol>
        </CRow>

        <InvoiceTable />
        <div className="text-right mt-2">
          <pre>
            <p>
              <strong>Sub Total:&nbsp;</strong>
              <span></span>
            </p>
            <p>
              <strong>Total:&nbsp;</strong>
              <span>{invoice.totalCost}</span>
            </p>
            <p>
              <strong>Payment Mode:&nbsp;</strong>
              <span></span>
            </p>
            <p>
              <strong>Balance Due:&nbsp;</strong>
              <span></span>
            </p>
          </pre>
        </div>
      </div>
    );
  };

  const InvoiceFooter = () => {
    return (
      <div className="small">
        <p>
          <strong>Term & Conditions</strong>
        </p>
        <p>This is computer generated bill no signature is required.</p>
      </div>
    );
  };
  const InvoiceTable = () => {
    return (
      <div>
        <table className="w-100">
          <thead>
            <tr>
              <th>S.NO.</th>
              <th>ITEM</th>
              <th>DESCRIPTION</th>
              <th>QTY</th>
              <th>RATE</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{invoice.planName}</td>
              <td>{}</td>
              <td>{invoice.purchasedLicenses}</td>
              <td>{invoice.perLicenseCost}</td>
              <td>{invoice.totalCost}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <div>
      {isloading ? (
        <div className="d-flex justify-content-center">
          <div
            id="tmf_invoice_container"
            className="w-50 bg-white my-5 page p-5"
            size="A3"
          >
            <InvoiceHeader />
            <InvoiceBody />
            <InvoiceFooter />
          </div>
          
        </div>
      ) : (
        <div className="spinner">
          <CSpinner color="info" />
        </div>
      )}
    </div>
  );
};

export default Invoice;
