import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Constants from "../../../constants/Constants";
import api from "services/api.service";
import Loader from "components/loader/Loader";
import { Controller, useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditOrganizationModal = ({ refreshDetails, organizationId, isOpen, onClose }) => {
  const [edit, setEdit] = useState(null);

  const [time, setTimeZone] = useState([]);
  const [isloading, setLoaded] = useState(false);
  const [countryList, setCountryList] = useState([]);
  // const [isLoadingStateList, setLoadState] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [isSpinning, setSpinning] = useState(false);
  const [preloadedValues, setPreloadedValues] = useState("");
  const [cSList, setCSList] = useState([]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: preloadedValues });

  useEffect(() => {
    loadTimezone();
    getCountryList();
    loadEdit();
  }, []);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(new Blob([file]));
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setEdit({ ...edit, logo: base64 });

  };

  const onInputChange = (e) => {
    setEdit({ ...edit, [e.target.name]: e.target.value });
  };

  const loadTimezone = () =>
    api.get(Constants.TIMEZONE_GET_URI).then((res) => setTimeZone(res.data));

  const onSubmit = () => {
    setSpinning(true);
    api
      .post(Constants.ORGANIZATION_UPDATE_POST_URI + "/" + organizationId, edit)
      .then(async () => {
        toast.success("😊 Organization edited successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        ////console.log(`Organization updated successfully!`);
        refreshDetails();
      }).catch((error) => {
        toast.error(`😔 Error while editing organization!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .finally(() => setSpinning(false));
  };

  const loadEdit = () => {
    setLoaded(true);
    api.post(Constants.ORGANIZATION_EDIT_URI + "/" + organizationId, edit)
      .then((res) => {
        const values = {
          name: res.data.name,
          phone: res.data.phone,
          email: res.data.email,
        };

        setPreloadedValues(values);

        setEdit(res.data);
      })
      .finally(() => setLoaded(false));
  };

  const getCountryList = () => {
    api.get(Constants.COUNTRY_GET_URI).then((res) => setCountryList(res.data));

  }

  const getStateList = (countryID) => {
    api.get(Constants.STATE_GET_URI + "/" + countryID).then((res) => {
      // setLoadState(true);       
      setStateList(res.data);
    });
  }

  const NameField = register("name", {
    required: true,
  });

  const EmailField = register("email", {
    required: true,
  });

  const PhoneNumberField = register("phone", {
    required: true,
  });
  const TimezoneField = register("timezone", {
    required: true,
  });
  const DefaultState = () => {
    if (stateList.length < 1) {
      return (<option key={0} value={edit.state} name="state">{edit.state_Name}</option>);
    } else {
      return <></>;
    }

  }
  return (
    <div>
      {!isloading && edit && countryList && time && (
        <CModal show={isOpen} onClose={onClose} closeOnBackdrop={false} style={{ maxHeight: "90vh", overflowY: "scroll" }}>
          <CModalHeader closeButton>
            <CModalTitle>
              {" "}
              <div>Edit Organization</div>{" "}
            </CModalTitle>
          </CModalHeader>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            <CModalBody>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Logo</label>
                    <div style={{ marginTop: "0.5rem" }}>
                      <input
                        type="file"
                        name="logo"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleFileUpload(e)}
                      />
                    </div>
                  </CFormGroup>
                </CCol>
                <CCol>
                  {edit.logo && (
                    <CFormGroup>
                      <img
                        src={edit.logo}
                        alt="logo"
                        style={{
                          width: 95,
                          height: 95,
                        }}
                      />
                    </CFormGroup>
                  )}
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={edit.name}
                      {...NameField}
                      onChange={(e) => {
                        NameField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.name?.type === "required" && "Name is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Address*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={edit.address}
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>E-mail*</label>
                    <input
                      type="email"
                      className="form-control "
                      name="email"
                      value={edit.email}
                      {...EmailField}
                      onChange={(e) => {
                        EmailField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control  "
                      name="city"
                      value={edit.city}
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Phone Number*</label>
                    <input
                      type="text"
                      className="form-control "
                      name="phone"
                      value={edit.phone}
                      {...PhoneNumberField}
                      onChange={(e) => {
                        PhoneNumberField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.phone?.type === "required" &&
                        "Phone Number is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Website</label>
                    <input
                      type="url"
                      className="form-control "
                      name="website"
                      value={edit.website}
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Fax</label>
                    <input
                      type="number"
                      className="form-control "
                      name="fax"
                      value={edit.fax}
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>TimeZone*</label>
                    <select
                      name="timezone"
                      {...TimezoneField}
                      onChange={(e) => {
                        TimezoneField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    >
                      <option value={edit.timezone} name="timezone">
                        {edit.displayName}
                      </option>
                      {time.map((temp) => {
                        return (
                          <>
                            <option value={temp.id} name="timezone">
                              {temp.displayName}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.timezone?.type === "required" &&
                        "Timezone is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Country</label>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onInputChange(e);
                            getStateList(e.target.value);

                          }}
                          className="form-control"
                          defaultValue={edit.country}
                        >
                          {
                            countryList.map((temp) => {
                              return (
                                <option value={temp.id} key={temp.id} name="country" selected={(edit.country == temp.id) ? "selected" : ""}>{temp.name} </option>
                              );

                            })}
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.country?.type === "required" &&
                        "Country is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>State </label>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <select
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            onInputChange(e);
                          }}
                          className="form-control"
                          defaultValue={edit.state}
                        >
                          <DefaultState />
                          {
                            stateList.map((temp) => {
                              return (<option value={temp.id} key={temp.id} name="state" selected={(edit.state == temp.id) ? "selected" : ""}>{temp.name} </option>);
                            })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.state?.type === "required" && "State is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Description</label>
                    <textarea
                      type="text"
                      className="form-control "
                      name="description"
                      value={edit.description}
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                  </CFormGroup>
                </CCol>
              </CRow>
            </CModalBody>
            {!isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton style={{ backgroundColor: "#1C6387", color: "white" }}
                  type="submit" size="sm">
                  Save Changes
                </CButton>
                <div className="ml-1">
                  <CButton onClick={onClose} color="secondary" size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
            {isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton disabled style={{ backgroundColor: "#1C6387", color: "white" }}
                  size="sm">
                  <i class="fa fa-spinner fa-spin"></i> Save Changes
                </CButton>
                <div className="ml-1">
                  <CButton onClick={onClose} color="secondary" size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
          </form>
        </CModal>
      )}
      {isloading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};
export default EditOrganizationModal;
