import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import api from "services/api.service";
import Constants from "../../../constants/Constants";
import { CCard, CCardBody, CDataTable } from "@coreui/react";
import Loader from "components/loader/Loader";

const LicenseDetails = () => {
  const [license, setLicense] = useState([]);
  const [loading, setLoading] = useState(false);

  const [popUpMenu, setPopUpMenu] = useState(false);

  let { organizationId } = useParams();


  useEffect(() => {
    loadLicenseDetails();
  },[]);

  const loadLicenseDetails = () =>
    api
      .get(Constants.LICENSE_DETAILS_URI + "/" + organizationId, {
        withCredentials: true,
      })
      .then((res) => {
        let myARRAY = [];
        myARRAY.push(res.data);
        setLicense(myARRAY);
      }).finally(() => setLoading(true));
  const fields = [
    { key: "name", _style: { width: "20%" } },
    { key: "purchasedLicenses", _style: { width: "20%" } },
    { key: "remainingLicence", _style: { width: "20%" } },
   
  ];

  return (
    <div>
      {loading ? (
        <div>
          <Link className="btn btn-info btn-sm" to="/organization">
            Back
          </Link>

          <div className="text-center">License Details</div>
          <CCard>
            <CCardBody>
              <CDataTable
                items={license}
                fields={fields}
                tableFilter={{ placeholder: "search" }}
                itemsPerPageSelect
                itemsPerPage={5}
                sorter
                pagination
                scopedSlots
              />
            </CCardBody>
          </CCard>
        </div>
      ) : (
        <div className="spinner">
            <Loader
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="2em"
            />
        </div>
      )}
    </div>
  );
};

export default LicenseDetails;
