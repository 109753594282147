import {
  CButton,
  CCol,
  CFormGroup,
  CInputCheckbox,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoIosAddCircle } from "react-icons/io";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

const EditSubscriptionModal = ({ refreshDetails, id, isOpen, onClose }) => {
  const [subscriptionModal, setSubscription] = useState(null);
  const [plan, setPlan] = useState([]);
  const [isloading, setLoaded] = useState(false);
  const [isSpinning, setSpinning] = useState(false);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [increaseSubs, setIncreasedValue] = useState(null);
  const [preloadedValues, setPreloadedValues] = useState(null);
  const [isPurchasedLicensesDisabled, setIsPurchasedLicensesDisabled] =
    useState(false);
  const [purchasedLicenses, setPurchaseLicense] = useState(
    subscriptionModal?.purchasedLicenses
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    loadPlan();
    loadSubscriptionTypes();
    loadSubscription();
    increaseSubscription();
  }, []);

  const onPlanInputChange = (e) => {
    let event = e.target;
    let plc = planDropDown.filter((item) => item.value == event.value)[0]
      .perLicenceCost;
    setSubscription({
      ...subscriptionModal,
      planID: event.value,
      perLicenseCost: plc,
    });
  };

  const onInputChange = (e) => {
    setSubscription({ ...subscriptionModal, [e.target.name]: e.target.value });
  };
  const planDropDown = plan.map((item) => {
    return {
      label: item.name,
      value: item.id,
      perLicenceCost: item.perLicenceCost,
    };
  });
  const onSubmit = () => {
    setSpinning(true);

    const currentDateUTC = new Date().toUTCString();
    const updatedSubscriptionModal = { ...subscriptionModal };

    if (new Date(updatedSubscriptionModal.endDate) < new Date(currentDateUTC)) {
      updatedSubscriptionModal.hasExpired = true;
    }else{
      updatedSubscriptionModal.hasExpired = false;
    }

    api
      .post(
        Constants.SUBSCRIPTION_UPDATE_POST_URI + "/" + id,
        updatedSubscriptionModal
      )
      .then(async (response) => {
        toast.success("😊 Subscription edited successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        refreshDetails();
      })
      .catch((error) => {
        toast.error(`😔 Error while editing subscription!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      })
      .finally(() => setSpinning(false));
  };

  const loadPlan = () =>
    api.get(Constants.PLAN_GET_URI).then((res) => setPlan(res.data));

  const loadSubscription = () => {
    setLoaded(true);
    api
      .post(Constants.SUBSCRIPTION_EDIT_URI + "/" + id)
      .then((res) => {
        // const values = {
        //   purchasedLicenses: res.data.purchasedLicenses,
        //   planName: res.data.planName,
        //   planID: res.data.planID,
        //   subscriptionType: res.data.subscriptionType,
        //   subscriptionTypeID: res.data.subscriptionTypeID
        // };
        // setPreloadedValues(values);
        setSubscription(res.data);
        setIncreasedValue(res.data.endDate ? res.data.endDate : "");
      })
      .finally(() => setLoaded(false));
  };

  const loadSubscriptionTypes = () =>
    api
      .get(Constants.SUBSCRIPTION_TYPES_GET_ALL_URI)
      .then((res) => setSubscriptionTypes(res.data));

  const purchasedLicensesField = register("purchasedLicenses", {
    required: false,
    min: 0,
  });
  const planField = register("planID", {
    required: false,
  });
  const SubscriptionTypeField = register("subscriptionTypeID", {
    required: false,
  });

  // const expandSubscription = register ("EndDate", {
  //   required:false,
  // });
  // //console.log("subscription ", subscriptionModal);

  const increaseSubscription = () => {
    var date = new Date(increaseSubs);
    date.setMonth(date.getMonth() + 1);
    setIncreasedValue(date);
    setSubscription({ ...subscriptionModal, endDate: increaseSubs });
  };

  return (
    <div>
      {!isloading && subscriptionModal && (
        <CModal show={isOpen} onClose={onClose}>
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Edit Subscription</div>{" "}
            </CModalTitle>
          </CModalHeader>

          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            <CModalBody>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Start Date</label>
                    <input
                      required
                      type="date"
                      name="startDate"
                      value={moment(subscriptionModal.startDate).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>End Date</label>
                    <input
                      required
                      type="date"
                      name="endDate"
                      value={moment(subscriptionModal.endDate).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      className="form-control"
                    />
                    <label>Increase subscription</label>
                    <IoIosAddCircle
                      size="25px"
                      onClick={increaseSubscription}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Plan</label>
                    <select
                      name="planID"
                      {...planField}
                      onChange={(e) => {
                        planField.onChange(e);
                        onPlanInputChange(e);
                      }}
                      className="form-control"
                      defaultValue={subscriptionModal.planID}
                    >
                      {plan.map((temp) => {
                        return (
                          <>
                            <option
                              value={temp.id}
                              key={temp.id}
                              selected={
                                subscriptionModal.planID === temp.id
                                  ? "selected"
                                  : ""
                              }
                            >
                              {temp.name}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.planID?.type === "required" && "Plan is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <label>Per License Cost</label>
                  <input
                    name="perLicenseCost"
                    className="form-control"
                    value={subscriptionModal.perLicenseCost}
                    disabled
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <label>Auto Renew</label>
                  <div className="form-control">
                    <CFormGroup>
                      <CInputCheckbox
                        name="autoRenew"
                        className="ml-1"
                        checked={subscriptionModal.autoRenew}
                        defaultValue={subscriptionModal.autoRenew}
                        onChange={() =>
                          setSubscription((subscriptionModal) => ({
                            ...subscriptionModal,
                            autoRenew: !subscriptionModal.autoRenew,
                          }))
                        }
                      />
                      <label
                        style={{ fontWeight: "bold", margin: "2px 0 0 20px" }}
                      >
                        Auto Renew
                      </label>
                    </CFormGroup>
                  </div>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Subscription Type</label>
                    <select
                      name="subscriptionTypeID"
                      {...SubscriptionTypeField}
                      onChange={(e) => {
                        SubscriptionTypeField.onChange(e);
                        onInputChange(e);
                        const selectedSubscriptionTypeID = parseInt(
                          e.target.value
                        );
                        if (selectedSubscriptionTypeID === 4) {
                          setSubscription((prevSubscription) => ({
                            ...prevSubscription,
                            purchasedLicenses: 0,
                          }));
                          setPurchaseLicense(0);
                          setIsPurchasedLicensesDisabled(true);
                        } else {
                          setIsPurchasedLicensesDisabled(false);
                          setPurchaseLicense();
                        }
                      }}
                      className="form-control"
                      defaultValue={subscriptionModal.subscriptionTypeID}
                    >
                      {subscriptionTypes.map((temp) => (
                        <option
                          value={temp.id}
                          key={temp.id}
                          selected={
                            subscriptionModal.subscriptionTypeID === temp.id
                              ? "selected"
                              : ""
                          }
                        >
                          {temp.type}
                        </option>
                      ))}
                    </select>
                    <small className="text-danger">
                      {errors.subscriptionTypeID?.type === "required" &&
                        "Subscription Type is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Purchased License*</label>
                    <input
                      type="number"
                      className="form-control"
                      name="purchasedLicenses"
                      defaultValue={subscriptionModal.purchasedLicenses}
                      value={purchasedLicenses}
                      {...purchasedLicensesField}
                      onChange={(e) => {
                        purchasedLicensesField.onChange(e);
                        onInputChange(e);
                      }}
                      disabled={isPurchasedLicensesDisabled}
                    />
                    <small className="text-danger">
                      {errors.purchasedLicenses?.type === "required" &&
                        "License is required"}
                      {errors.purchasedLicenses?.type === "min" &&
                        "Please enter a positive number"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
            </CModalBody>
            {!isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton
                  style={{ backgroundColor: "#1C6387", color: "white" }}
                  type="submit"
                  size="sm"
                >
                  Save Changes
                </CButton>
                <div className="ml-1">
                  <CButton onClick={onClose} color="secondary" size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
            {isSpinning && (
              <div className="float-right mb-1 mr-1 d-flex">
                <CButton disabled color="primary" size="sm">
                  <i className="fa fa-spinner fa-spin"></i> Updating
                </CButton>
                <div className="ml-1">
                  <CButton onClick={onClose} color="secondary" size="sm">
                    Close
                  </CButton>
                </div>
              </div>
            )}
          </form>
        </CModal>
      )}
      {isloading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default EditSubscriptionModal;
