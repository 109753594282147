import {
  CDropdown,
  CDropdownItem
} from "@coreui/react";
import { authContext } from "auth/auth.provider";
import avatarInitials from "helper/avatarInitials";
import colors from "helper/colors";
import { useContext, useEffect, useState } from "react";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaLinkedin, FaYoutube } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { SiMicrosoftteams } from "react-icons/si";

interface IuserInterface {
  initial: string;
  color: string | null;
}

const NavigationHeaderAccountDropdown = () => {
  const { user, onLogout } = useContext(authContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [userInitials, setUserInitials] = useState<IuserInterface>();
  // const handleLogout = () => {
  //   onLogout();
  // };

  const [showWindowSidebar, setShowWindowSidebar] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      if (newWidth > 1600) {

        setShowWindowSidebar(true)
      } else {
        setShowWindowSidebar(false)
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  useEffect(() => {
    setUserInitials({
      initial: avatarInitials.getAvatarInitials(user.userName),
      color: colors.getStringColor(user.userName),
    });
  }, [user]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <CDropdown inNav className="w-100 c-header-nav-items mx-2">
      {/* <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div
          className="c-avatar"
          style={{ backgroundColor: `${userInitials?.color}` }}
        >
          {userInitials?.initial}
        </div>
      </CDropdownToggle> */}

      <div style={{
        display: 'flex',
        gap: "10px",
        alignItems: "center",
        zIndex: dropdownOpen ? -2 : 2,
        cursor: "pointer",
      }}
        onClick={toggleDropdown}
      >
        <div
          className="c-avatar"
          style={{
            backgroundColor: `${userInitials?.color}`,
          }}
        >
          {userInitials?.initial}

        </div>
        <IoIosArrowDown />
      </div>

      <div
        style={{
          width: dropdownOpen ? showWindowSidebar ? "350px" : "250px" : "0px",
          boxShadow: dropdownOpen ? "0px 34px 85px 0px rgba(0, 0, 0, 0.25)" : "none",
          padding: dropdownOpen ? '10px' : '0px',
          opacity: dropdownOpen ? 1 : 0,
          transform: `translateY(${dropdownOpen ? '0' : '-100%'})`,
        }}
        className="profile"
      >
        {user ? (
          <>
            <div className="profile-title" >
              <div className="profile-name">
                <div
                  className="c-avatar"
                  style={{
                    backgroundColor: `${userInitials?.color}`,
                  }}
                >
                  {userInitials?.initial}
                </div>
                <span >{user.userName}</span>
              </div>
              <IoIosArrowUp
                onClick={toggleDropdown} />
            </div>

            <div className="profile-desc">
              {/* <CIcon name="cil-user" className="mfe-2" size="2xl" /> */}
              <p> {user.orgName}</p>
              <p>{user.email}</p>
            </div>

            <CDropdownItem divider />


            <div className="social-media-links text-center"        >

              <a
                href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fchat%2F0%2F0%3Fusers%3Dhello%40tagmyfav.com&type=chat&deeplinkId=73380261-73dc-4324-8fef-4635490b50eb&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true"
                target="_blank"
                rel="noreferrer noopener"
                style={{
                  backgroundColor: "#4f57be"
                }}

              >

                <SiMicrosoftteams />

              </a>
              <a

                href="https://www.youtube.com/@tagmyfav6308"
                target="_blank"
                rel="noreferrer noopener"
                className="red"
              >

                <FaYoutube />

              </a>
              <a

                href="https://twitter.com/tag_myfav"
                target="_blank"
                rel="noreferrer noopener"
                style={{
                  backgroundColor: "black"
                }}
              >
                <BsTwitterX />

              </a>
              <a

                href="https://www.linkedin.com/showcase/tagmyfav/"
                target="_blank"
                rel="noreferrer noopener"
                className="dark-blue"
              >
                <FaLinkedin />
              </a>
            </div>

            <CDropdownItem divider />


            <div
              className="info"
            >
              <a
                href="https://app.tagmyfav.com"
                target="_blank"
                rel="noreferrer noopener"

              >
                <small > Get Web App!</small>
              </a>
              <a
                href="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fchat%2F0%2F0%3Fusers%3Dhello%40tagmyfav.com&type=chat&deeplinkId=73380261-73dc-4324-8fef-4635490b50eb&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true"
                target="_blank"
                rel="noreferrer noopener" // Add 'noopener' value to the 'rel' attribute
                className="d-block"

              >
                <small >Support FAQ!</small>
              </a>
              <button
                onClick={onLogout}
                className="logout-btn"
              >
                Logout
              </button>
            </div>


          </>
        ) : (
          <div></div>
        )}
      </div>

    </CDropdown>
  );
};

export default NavigationHeaderAccountDropdown;
