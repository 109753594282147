
import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import  { useState } from "react";
import Constants from "../../../constants/Constants";

const UndoDeleteSubscriptionModal = ({ isOpen, onClose, id, refreshDetails }) => {
  const [isSpinning, setSpinning] = useState(false);

  const deleteSubscription = async () => {
    setSpinning(true);
    await api
      .post(`${Constants.UNDO_DELETE_SUBSCRIPTION_URI}/${id}`)
      .then(async (res) => {
        setSpinning(false);
        await onClose();
        //console.log(`Subscription Undo Delete successfully!`);
        refreshDetails();
      });
  };

  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        {/* <CModalHeader>
          <CModalTitle>
            <Text fontSize="xl"> Delete Subscription</Text>
          </CModalTitle>
        </CModalHeader> */}
        <CModalBody>
          Do you want to undo delete this Subscription?
          {!isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton
                color="info"
                onClick={() => deleteSubscription()}
                size="sm"
              >
                Ok
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
          {isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="primary" disabled size="sm">
                <i class="fa fa-spinner fa-spin"></i> Deleting
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default UndoDeleteSubscriptionModal;
