import { createContext, useState, FC, useEffect } from "react";
import Loader from "components/loader/Loader";
import cookieService from "services/cookies.service";
import { CFade } from "@coreui/react";
import commonApi from "services/apis/common.api";
import organizationApi from "services/apis/organization.api";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import subscriptionApi from "services/apis/subscription.api";
export interface IAuth {
  user: IAuthUser;
  onLogin: (token: string) => void;
  onLogout: () => void;
  isAuthenticated: boolean;
}

const authContentDefaults: IAuth = {
  user: {
    email: "",
    orgID: 0,
    orgName: "",
    subscriptionID: 0,
    userID: 0,
    userName: "",
  },
  onLogin: () => {},
  onLogout: () => {},
  isAuthenticated: false,
};

const LOCAL_STORAGE_KEY = 'sidebar-dropdowns';


export const authContext = createContext<IAuth>(authContentDefaults);

const AuthProvider: FC = ({ children }) => {
  const dispatch: Dispatch = useDispatch();
  const [user, setUser] = useState<IAuthUser>(authContentDefaults.user);
  const [isLoading, setLoader] = useState<boolean>(true);
  const [isAuthenticated, setAuthentication] = useState<boolean>(
    authContentDefaults.isAuthenticated
  );

  useEffect(() => {
    setLoader(true);
    getUserHandler();
  }, []);

  const getUserHandler = () => {
    commonApi
      .fetchUser()
      .then((res) => {
        setAuthentication(true);
        setUser(res);
        fetchInitialApis();
      })
      .catch((error: XMLHttpRequest) => {
        if (error.status === 401) {
          setAuthentication(false);
        }
      })
      .finally(() =>
        setTimeout(() => {
          setLoader(false);
        }, 0)
      );
  };

  const fetchCountries = () => commonApi.fetchCountries(dispatch);
  const fetchTimezones = () => commonApi.fetchTimezones(dispatch);
  const fetchSubscriptionTypes = () =>
    subscriptionApi.fetchSubscriptionTypes(dispatch);
  const fetchOrgnizationTypes = () =>
    organizationApi.fetchOrganizationTypes(dispatch);

  const fetchInitialApis = () => {
    //getUserHandler();
    fetchCountries();
    fetchTimezones();
    fetchSubscriptionTypes();
    fetchOrgnizationTypes();
  };

  const loginHandler = (token: string) => {
    cookieService.set("token", token, 100);
    fetchInitialApis();
  };

  const logoutHandler = () => {
    cookieService.delete("token");
    localStorage.removeItem(LOCAL_STORAGE_KEY)
    setAuthentication(false);
    setUser(authContentDefaults.user);
  };

  return (
    <authContext.Provider
      value={{
        user,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        isAuthenticated,
      }}
    >
      {isLoading ? (
        <div className="ltb-loader-page">
          <Loader size="50px"></Loader>
        </div>
      ) : (
        <CFade>{children}</CFade>
      )}
    </authContext.Provider>
  );
};
export default AuthProvider;
