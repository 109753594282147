import { useState } from "react";
import { FcFolder } from "react-icons/fc";
import AddModal from "./AddModal";
import AddChildModal from "./AddChildModal";

import { CCol, CRow } from "@coreui/react";
// const useStyles = makeStyles({
//   root: {
//     height: 240,
//     flexGrow: 1,
//     maxWidth: 400,
//   },
// });
const Template = () => {
  // const classes = useStyles();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addChildModalOpen, setAddChildModalOpen] = useState(false);
  const [category, setCategory] = useState([]);
  //console.log({ category });
  // const [childFolder, setChildFolder] = useState([]);

  const [id, setId] = useState(null);

  const handleModalOpen = () => {
    setAddModalOpen(true);
  };
  const handleChildModalOpen = (id) => {
    setAddChildModalOpen(true);
    setId(id);
  };
  const loadCategory = (newFolder) => {
    //console.log({ newFolder });
    setCategory([...category, newFolder]);
  };
  const loadChild = (newChild) => {
    //console.log({ newChild });
    setCategory([...category, newChild]);
  };

  return (
    <div>
      {addModalOpen && (
        <AddModal
          isOpen={addModalOpen}
          onClose={() => setAddModalOpen(false)}
          create={loadCategory}
        />
      )}
      {addChildModalOpen && (
        <AddChildModal
          isOpen={addChildModalOpen}
          onClose={() => setAddChildModalOpen()}
          create={loadChild}
          id={id}
        />
      )}

      <div onClick={() => handleModalOpen()}>
        <FcFolder size={25} />
      </div>
      {category && (
        <>
          <div style={{ marginTop: "5rem" }}>
            <CRow>
              <CCol xs="4">
                {category.map((item) => {
                  return (
                    <>
                      {/* <div className="accordion">
                        <div className="accordion-item">
                          <ul id="category-folder" value={item.id}>
                            <div className="d-flex">
                              {item.folder}{" "}
                              <div
                                onClick={() => handleChildModalOpen(item.id)}
                              >
                                <GrFormAdd />
                              </div>
                            </div>
                          </ul>
                          <AccordionButton>
                            <div flex="1" textAlign="left"></div>
                            <AiOutlineArrowDown size={15} />
                          </AccordionButton>
                          <AccordionPanel>
                            {" "}
                            <div className="mt-3 ml-4 ">
                              {category.map((item) => {
                                return (
                                  <>
                                    <div className="accordion">
                                      <div className="accordion-item">
                                        {id === item.parent ? (
                                          <ul
                                            id="child-folder"
                                            value={item.parent}
                                          >
                                            {//console.log({ id })}
                                            <div className="ml-3 mt-1 d-flex">
                                              {item.child}{" "}
                                            </div>
                                          </ul>
                                        ) : (
                                          <div></div>
                                        )}

                                        <AccordionButton>
                                          <div flex="1" textAlign="left"></div>
                                          <AiOutlineArrowDown size={15} />
                                        </AccordionButton>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </AccordionPanel>
                        </div>
                      </div> */}
                    </>
                  );
                })}
              </CCol>
            </CRow>
          </div>
        </>
      )}
    </div>
  );
};

export default Template;
