import { useContext, useEffect } from "react";
import { selectContext } from "./select.provider";

interface ISingleSelectInputProps extends React.ComponentProps<"input"> {}

export default function SingleSelectInput({
  ...props
}: ISingleSelectInputProps) {
  const { selectedOption } = useContext(selectContext);

  var propsWithoutInput = { ...props };
  delete propsWithoutInput.name;

  return (
    <>
      <input
        type="text"
        className="input"
        key={selectedOption?.label ? Math.random() : 0}
        defaultValue={selectedOption?.label || ""}
        // readOnly
        multiple
        {...propsWithoutInput}
      />
      <input
        hidden
        type="text"
        className="input"
        readOnly
        value={selectedOption?.value || ""}
        {...props}
      />
    </>
  );
}
