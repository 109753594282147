import { CButton } from "@coreui/react";
import DataFilter from "components/common/dataFilter";
import Constants from "constants/Constants";
import commonConstants from "constants/commonConstants";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import leadsApi from "services/apis/leads.api";
import { ApplicationState } from "store";
import { setLeadsFilter } from "store/leads/action";

const LeadsFilter = ({ cPage, onPageChange }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [showFields, setShowFields] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const dateRangeRef = useRef<HTMLDivElement | null>(null);
  const [openSelects, setOpenSelects] = useState({
    LeadSource: false,
    IsSubscribed: false
  });

  const leadsFilter = useSelector(
    (state: ApplicationState) => state.lead.leadsFilter
  );

  const handleSelectClick = (selectName) => {
    setOpenSelects(prev => ({
      ...prev,
      [selectName]: !prev[selectName]
    }));
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue
  } = useForm();

  function handleChange(
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) {

    const selectedValue = e.target.value;
    setSelectedDateRange(selectedValue);
    if (selectedValue == "Select Date Range") {
      setValue("BeginDate", null)
      setValue("EndDate", undefined);
      dispatch(
        setLeadsFilter({
          ...leadsFilter,
          BeginDate: null,
          EndDate: null,
        })
      );

      return;
    }
    let startDate;
    let endDate = new Date();

    if (selectedValue.includes("Last")) {
      switch (selectedValue) {
        case "Last 30 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 30);
          break;
        case "Last 60 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 60);
          break;
        case "Last 90 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 90);
          break;
        case "Last Week":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 7);
          break;
      }

      setValue("EndDate", endDate.toISOString().split("T")[0])

      dispatch(
        setLeadsFilter({
          ...leadsFilter,
          BeginDate: startDate.toISOString().split("T")[0],
          EndDate: endDate.toISOString().split("T")[0],
        })
      );

    } else {

      if (e.target.name === "EndDate") {
        setValue("EndDate", endDate.toISOString().split("T")[0])
      }

      dispatch(
        setLeadsFilter({
          ...leadsFilter,
          [e.target.name]: e.target.value?.length ? e.target.value : null,
        })
      );
    }
  }


  const checkEndData = watch("EndDate");

  const handleClearFilter = (clearAll = true) => {
    leadsFilter.BeginDate = null;
    leadsFilter.EndDate = null;


    if (clearAll) {
      leadsFilter.IsSubscribed = null;
      leadsFilter.LeadSource = null;
    }

    leadsApi.filterLeads(
      leadsFilter,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1);
    setValue("EndDate", undefined);
    setShowFields(false);
  };

  const loadDateRange = () => {
    leadsApi.filterLeads(
      leadsFilter,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1);
  };

  // const EndDateField = register("EndDate", {
  //   required: true,
  // });


  const handleClicks = () => {
    setShowFields(!showFields);
  };

  //used to close the date range popup when click outside the popup box
  useEffect(() => {
    if (leadsFilter.BeginDate !== null && checkEndData === undefined) {
      return;
    } else {
      const handleClickOutside = (event: MouseEvent) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
          if (dateRangeRef.current) {
            setShowFields(false)
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [leadsFilter.BeginDate, checkEndData]);

  //used to call the organization data when filter value is changed.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedDateRange !== "Select Date Range" && leadsFilter.BeginDate == null && leadsFilter.EndDate == null || selectedDateRange !== "Select Date Range" && leadsFilter.BeginDate != null && leadsFilter.EndDate != null) {
        loadDateRange()
        setShowFields(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [leadsFilter])


  return (

    <DataFilter>
      <form
        onSubmit={handleSubmit(loadDateRange)}
        className="filter"
      >
        <div>
          <label>Lead Source</label>
          <div className="select-wrapper">
            <select
              name="LeadSource"
              onChange={(e) => handleChange(e)}
              className="form-control"
              defaultValue={leadsFilter.LeadSource?.toString()}
              onClick={() => handleSelectClick('LeadSource')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, LeadSource: false }))}
            >
              <option value="">Select Lead Source</option>
              {Constants.LEAD_SOURCE.map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            {openSelects.LeadSource ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>

        <div>
          <label>Subscribed-Status</label>
          <div className="select-wrapper">
            <select
              name="IsSubscribed"
              onChange={(e) => handleChange(e)}
              className="form-control"
              defaultValue={leadsFilter?.IsSubscribed?.toString()}
              onClick={() => handleSelectClick('IsSubscribed')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, IsSubscribed: false }))}
            >
              <option value="">Select Lead Source</option>
              <option value="true">Subscribed</option>
              <option value="false">UnSubscribed</option>
            </select>
            {openSelects.IsSubscribed ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>

        {/* <div className="tmf-form-group">
          <label className="textBold">Start-Date</label>{" "}
          <input
            placeholder="date"
            className="form-control"
            type="date"
            name="BeginDate"
            onChange={(e) => handleChange(e)}
          />
        </div>
        {leadsFilter.BeginDate ? (
          <>
            <div className="tmf-form-group">
              <label className="textBold">End-Date</label>
              <input
                placeholder="date"
                className="form-control"
                type="date"
                {...EndDateField}
                onChange={(e) => {
                  EndDateField.onChange(e);
                  handleChange(e);
                }}
                min={leadsFilter.BeginDate as string}
              />
              <small className="text-danger">
                {errors.EndDate?.type === "required" && "End Date is required"}
              </small>
            </div>
          </>
        ) : (
          <div className="tmf-form-group">
            <label className="textBold">End-Date</label>
            <input
              disabled
              className="form-control"
              type="date"
              name="EndDate"
            />
          </div>
        )} */}

        <div className="relative" >
          <label>Date Range</label>

          <div className="date-range">
            <input
              readOnly
              className="form-control"
              placeholder={`${leadsFilter.BeginDate ? leadsFilter.BeginDate : "Select Date Range"} ${leadsFilter.EndDate ? "-" : ""} ${leadsFilter.EndDate ? leadsFilter.EndDate : ""}`}
            ></input>
            {
              showFields ? <RiArrowDropUpLine onClick={handleClicks} /> : <RiArrowDropDownLine onClick={handleClicks} />
            }
          </div>


          {showFields && (
            <div ref={dateRangeRef} className={`dateRangeRef ${showFields ? 'show' : 'hide'}`}>
              <div className="flex" style={{ gap: "0.5rem", alignItems: "stretch", flexWrap: "wrap" }}>
                <fieldset>
                  <div className="tmf-form-group">
                    <label>Date Ranges</label>
                    <select
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select Date Range</option>
                      <option value="Last 30 Days">Last 30 Days</option>
                      <option value="Last 60 Days">Last 60 Days</option>
                      <option value="Last 90 Days">Last 90 Days</option>
                      <option value="Last Week">Last Week</option>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="tmf-form-group">
                    <label>Start Date</label>
                    <input
                      placeholder="date"
                      className="form-control sm"
                      type="date"
                      name="BeginDate"
                      value={`${leadsFilter.BeginDate}`}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </fieldset>
                <fieldset>
                  {leadsFilter.BeginDate ? (
                    <div className="tmf-form-group">
                      <label>End Date</label>
                      <input
                        placeholder="date"
                        className="form-control"
                        type="date"
                        value={`${leadsFilter.EndDate}`}
                        name="EndDate"
                        // {...EndDateField}
                        onChange={(e) =>
                          // EndDateField.onChange(e);
                          handleChange(e)
                        }
                        min={leadsFilter.BeginDate as string}
                      />

                      <small className="text-danger">
                        {/* {errors.EndDate?.type === "required" &&
                          "End Date is required"} */}
                        {checkEndData === undefined ? "End Date is required" : ""}
                      </small>
                    </div>
                  ) : (
                    <div className="tmf-form-group">
                      <label>End Date</label>
                      <input disabled className="form-control" type="date" value={`${leadsFilter.EndDate}`} />
                    </div>
                  )}
                </fieldset>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <div className="tmf-filter__submit">
                  <button
                    className="apply"
                    type="submit"
                    disabled={checkEndData === undefined ? true : false}
                    onClick={() => {
                      loadDateRange();
                      setShowFields(!showFields);
                    }}
                  >
                    Apply
                  </button>
                </div> */}
                <div className="tmf-filter__submit">
                  <button
                    className="clear"
                    type="reset"
                    onClick={() => handleClearFilter(false)}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="tmf-filter__submit">
          <button
            onClick={() => loadDateRange()}
            className="apply"
            type="submit"
          >
            Apply
          </button>
        </div>

        <div className="tmf-filter__submit">
          <button
            onClick={() => {
              //   loadDateRange();
              // setShowFields(!showFields);
              handleClearFilter();

            }}
            className="clear"
            type="reset"
          >
            Clear
          </button>
        </div>

      </form>
    </DataFilter>
  );
};

export default LeadsFilter;
