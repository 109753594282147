import { CButton } from "@coreui/react";
import axios from "axios";
import { count } from "console";
import React, { Dispatch, useEffect, useState } from "react";
import {
  setAllLeadLoader,
  setAllLeads,
  setLeadSearchValue,
  setLeadsFilter,
} from "store/leads/action";
import {
  setOrganizations,
  setOrganizationsLoader,
} from "store/organization/action";
import {
  setFilteredSubscriptions,
  setFilteredSubscriptionsLoader,
} from "store/subscription/action";
import {
  setUserLoader,
  setUsers,
  setUsersFilter,
  setUsersLoader,
} from "store/user/action";
import { ImCancelCircle } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { setAllPlans, setPlanFilterDataLoader } from "store/plan/action";
import { setAllOfferLoader, setAllOffers } from "store/offer/action";
import { setAllPartnerLoader, setAllPartners } from "store/partner/action";
import { FaSearch } from "react-icons/fa";
import { MdCancel, MdOutlineCancel } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

const TMFSearch = ({
  sapi,
  dispatch,
  isSearchActive,
  searchIn,
  onCalcelSearch,
  Placeholder="Search by Name,Email"
}) => {
  const leadSearchValue = useSelector(
    (state: ApplicationState) => state.lead.leadSearchValue
  );
  // const dispatchForSearchValue: Dispatch<any> = useDispatch();
  const [searchValue, setSearchValue] = useState<string | undefined>(
    leadSearchValue ? leadSearchValue : ""
  );
  const [error, setError] = useState<string>();
  const REGREX_VALIDATION = "^[a-zA-Z0-9.@]*$";

  const setData = (dispatch, searchin, res) => {
    switch (searchin) {
      case "organizations":
        dispatch(setOrganizations(res.data));
        break;
      case "subscriptions":
        dispatch(setFilteredSubscriptions(res.data));
        break;
      case "users":
        dispatch(setUsers(res.data));
        break;
      case "leads":
        dispatch(setAllLeads(res.data));
        break;
      case "plans":
        dispatch(setAllPlans(res.data));
        break;
      case "offers":
        dispatch(setAllOffers(res.data));
        break;
      default:
        break;
    }
  };
  const handleSearch = () => {
    if (searchValue === "") {
      setError("Please Enter Something...");
    } else {
      dispatch(setLeadSearchValue(searchValue));
      dispatch(setOrganizationsLoader(true));
      dispatch(setFilteredSubscriptionsLoader(true));
      dispatch(setUsersLoader(true));
      dispatch(setAllLeadLoader(true));
      dispatch(setPlanFilterDataLoader(true));
      dispatch(setAllPartnerLoader(true));
      dispatch(setAllOfferLoader(true));
      axios
        .get(`${sapi}/${searchValue}`)
        .then((res) => {
          setData(dispatch, searchIn, res);
          isSearchActive(true);
        })
        .catch((error) => { })
        .finally(() => {
          dispatch(setOrganizationsLoader(false));
          dispatch(setFilteredSubscriptionsLoader(false));
          dispatch(setUsersLoader(false));
          dispatch(setAllLeadLoader(false));
          dispatch(setPlanFilterDataLoader(false));
          dispatch(setAllPartnerLoader(false));
          dispatch(setAllOfferLoader(false));
        });
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleClearSearch = () => {
    dispatch(setLeadSearchValue(""));
    // setSearchValue("");
    onCalcelSearch();
  };

  return (
    <span className="search">
      <CButton
        size="sm"
        onClick={() => handleSearch()}
      >
        <div className="d-flex ">
          <FaSearch style={{ height: "16px", width: "16px" }} />
        </div>

      </CButton>
      <input
        pattern={REGREX_VALIDATION}
        type="text"
        value={searchValue}
        autoComplete="off"
        placeholder={error || Placeholder}
        onChange={(e) => handleChange(e)}

        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (searchValue?.trim() === "") {
              handleClearSearch();
            } else {
              handleSearch();
            }
          }
        }}
      />

      {searchValue && (
        <IoMdClose
          onClick={() => handleClearSearch()}
          style={{
            cursor: "pointer",
            height: "20px",
            width: "20px",
          }} />

      )}

      {/* <small className="d-block text-danger">{error}</small> */}
    </span>
  );
};

export default TMFSearch;
