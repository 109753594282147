import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import { ReactNode } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import "styles/_dropdown.scss";

interface ITmfDropdown {
  toggleIcon?: string;
  props?: CDropdown;
  children?: ReactNode;
}

const TmfDropdown = ({
  toggleIcon = "caret-down",
  props,
  children,
}: ITmfDropdown) => {
  return (
    <CDropdown
      className="tmf-dropdown"
      {...props}
      style={{
        borderRadius: "0px 25px 25px 0px",
        borderTop: " 1px solid var(--primary, #1C63B7)",
        borderRight: "1px solid var(--primary, #1C63B7)",
        borderBottom: "1px solid var(--primary, #1C63B7)",
        height: "40px",
      }}
    >
      <CDropdownToggle
        caret={false}
        size="sm"
        className="dropdown"
        style={{ outline: "#fff" }}
      >
        {/* {
          showFields ?     <RiArrowDropUpLine />  : <RiArrowDropDownLine />
        } */}
   
        <RiArrowDropDownLine style={{height:"20px",width:"20px"}} />
      </CDropdownToggle>
      <CDropdownMenu>{children}</CDropdownMenu>
    </CDropdown>
  );
};

export default TmfDropdown;
