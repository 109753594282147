import { useEffect, useState } from "react";
import api from "services/api.service";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CCol,
  CFormGroup,
} from "@coreui/react";
import Constants from "../../../constants/Constants";
import Loader from "components/loader/Loader";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import moment from "moment";
import { DefaultSubscription } from "./MailTemplate";

const SubscriptionMailModal = ({
  subscriptionDetails,
  refreshDetails,
  isOpen,
  onClose,
  orgDataID,
  endDate,
}) => {
  interface ISubscriptionMailModal {
    email?: string;
    subject?: string;
    messagebody?: any | HTMLElement;
  }
  interface IOrganizationData {
    name: string;
    email: string;
    orgType: string | null | number;
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [orgId, setOrgId] = useState(orgDataID);
  const [orgData, setOrgData] = useState<IOrganizationData>();
  const [mail, setMail] = useState<ISubscriptionMailModal>();
  const [isSpinning, setSpinning] = useState(false);
  const [isloading, setLoaded] = useState(false);
  const editorRef = useRef();
  const [mailContent, setMailContent] = useState<any | HTMLElement>();
  useEffect(() => {
    const loadOrgData = async () => {
      const result = await api.getWithParams(
        Constants.ORGANIZATION_GET_ONLY_ORG_DETAILS_URI + `/${orgId}`,
        {
          withCredentials: true,
        }
      );
      setOrgData({
        name: result.data.name,
        email: result.data.email,
        orgType: result.data.orgType,
      });
      setMail({
        ...mail,
        email: result.data.email,
        subject: `Update Your Subscription Information`,
      });
      setMailContent(
        DefaultSubscription(
          subscriptionDetails.id,
          subscriptionDetails.name,
          subscriptionDetails.organizationName,
          subscriptionDetails.subscriptionType,
          subscriptionDetails.endDate,
          result.data.totalPaymentsCount,
        )
        // `Subscription is going to be expired ${moment(
        //   endDate ? endDate : null
        // ).format("MMMM Do YYYY, h:mm:ss a")} please renew in time.`
      );
    };
    loadOrgData();
  
  }, []);
  const onSubmit = () => {
    setSpinning(true);
    api
      .post(Constants.SEND_MAIL, mail)
      .then(async () => {
        await onClose();
        alert(`Mail send successfully!`);
        refreshDetails();
        setMail({
          email: "",
          subject: "",
          messagebody: "",
        });
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  const handleEditorChange = (content: any, editor: any) => {
    setMail({ ...mail, messagebody: content });
  };
  return (
    <>
      {isOpen && (
        <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true} style={{ width: "600px" }}>
          <CModalHeader closeButton>
            <CModalTitle>
              <div className="text-capitalize">
                send subscription mail to {orgData?.name}
              </div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >
              <CRow>
                <CCol>
                  <CFormGroup>
                    {orgData?.orgType && (
                      <>
                        <label>Organization Type</label>
                        <span>{orgData.orgType}</span>
                      </>
                    )}
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      value={"theengswostikaa@gmail.com"}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Message</label>
                    <div className="" style={{ border: "1px solid #ccc" }}>
                      <Editor
                        apiKey="8qt7b2xhusy7x4d51lu9o3nqw886wgi0ozb73g2m9ayui3wc"
                        init={{
                          skin: "snow",
                          icons: "thin",
                          height: 400,
                          width:550,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen textcolor ",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          textcolor_rows: "4",
                          toolbar:
                            "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
                        }}
                        initialValue={mailContent}
                        onEditorChange={handleEditorChange}
                        // outputFormat="html"
                      />
                    </div>
                    <small className="text-danger">
                      {errors.message?.type === "required" &&
                        "Email is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton color="info" type="submit" size="sm">
                    Send
                  </CButton>

                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Cancel
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton disabled color="primary" size="sm">
                    <i className="fa fa-spinner fa-spin"></i> Sending...
                  </CButton>
                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </form>
          </CModalBody>
        </CModal>
      )}
      {isloading && <Loader />}
    </>
  );
};

export default SubscriptionMailModal;
