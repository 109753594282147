import AvishSelect from "components/formElement/select";
import AvishModal from "components/modal/modal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import organizationApi from "services/apis/organization.api";
import partnerApi from "services/apis/partner.api";
import { ApplicationState } from "store";
import { resetAddPartnerModal } from "store/partner/action";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPartnerModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const addPartnerModal = useSelector(
    (state: ApplicationState) => state.partner?.addPartnerModal
  );
  const allOrganizations = useSelector(
    (state: ApplicationState) => state.organization?.allOrganizations
  );
  const partnerTypes = useSelector(
    (state: ApplicationState) => state.partner?.partnerTypes
  );

  useEffect(() => {
    organizationApi.fetchAllOrganizations(dispatch, false);
    partnerApi.fetchPartnerTypes(dispatch);
  }, []);

  function closeHandler() {
    dispatch(resetAddPartnerModal());
  }

  function submitHandler(e) {
    const data = new FormData(e.target);

    var formData: IPartnerFormData = [...data.entries()].reduce((attr, el) => {
      attr[el[0]] = el[1];
      return attr;
    }, {} as IPartnerFormData);
    partnerApi
      .createNewPartner(dispatch, formData)
      .then(() => {
        toast.success("😊 Partner added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        closeHandler();
      })
      .catch((error) => {
        toast.error(`😔 Error while adding partner!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  }

  const ModalIconBuild = () => {
    return (
      <svg viewBox="0 0 20 20" fill="currentColor">
        <defs>
          <linearGradient x1="16%" x2="84%" y1="0%" y2="100%" id="linear">
            <stop offset="0%" stopColor="#00B5FF" stopOpacity="1"></stop>
            <stop offset="55%" stopColor="#2F578A" stopOpacity="1"></stop>
          </linearGradient>
        </defs>
        <path
          fill="url(#linear)"
          d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
        />
      </svg>
    );
  };
  return addPartnerModal?.showModal ? (
    <AvishModal
      submitButtonType="submit"
      submitButtonValue="Add Partner"
      title="Add Partner"
      icon={<ModalIconBuild></ModalIconBuild>}
      onClose={closeHandler}
      onSubmit={submitHandler}
      showModal={!!addPartnerModal?.showModal}
      isFormSubmitting={!!addPartnerModal?.isModalFormSubmitting}
    >
      <AvishSelect
        options={allOrganizations.reduce((attr, el) => {
          var type: ISelectData = {
            value: el.organizationID.toString(),
            label: el.name,
          };
          attr.push(type);
          return attr;
        }, [] as ISelectData[])}
        name="orgID"
        required
        label="Organization*"
      ></AvishSelect>
      <AvishSelect
        options={
          partnerTypes?.reduce((attr, el) => {
            var type: ISelectData = {
              value: el.partnerTypeId.toString(),
              label: el.partnerName,
            };
            attr.push(type);
            return attr;
          }, [] as ISelectData[]) || []
        }
        name="partnerTypeID"
        required
        label="Partner Type*"
      ></AvishSelect>
    </AvishModal>
  ) : (
    <></>
  );
};

export default AddPartnerModal;
